import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { BackHandler, Dimensions, Image, InputAccessoryView, Share, Keyboard, KeyboardAvoidingView, Modal, Platform, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { isTablet } from 'react-native-device-info';
import RNModal from 'react-native-modal';
import { EventRegister } from 'react-native-event-listeners';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { useToast } from 'react-native-toast-notifications';
import _ from 'lodash';
import DataController from '../controllers/DataController';
import images from '../resources/images';

const PDFAnnotationModal = ({ screenProps, visible, close, shareAnnotation, deleteAnnotation, currentDocument, downloadText }) => {
  const toast = useToast();
  const [mode, setMode] = useState('list');
  const [page, setPage] = useState(1);
  const [document, setDocument] = useState(currentDocument);
  const [allNotes, setAllNotes] = useState([]);
  const [noteText, setNoteText] = useState('');
  const [noteId, setNoteId] = useState(null);
  const [keyboardActive, setKeyboardActive] = useState(false);

  useEffect(() => {
    getAllNotes();
  }, []);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardActive(true);
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardActive(false);
    });

    EventRegister.addEventListener('pdf/open-page-annotation', ({ pageNumber, id, doc }) => {
      setPage(pageNumber);
      setMode('note');
      setDocument(doc);
    });

    EventRegister.addEventListener('pdf/open-list', ({ id, doc }) => {
      setMode('list');
      setDocument(doc);
    });

    EventRegister.addEventListener('notes/notes-updated', () => getAllNotes());

    return () => {
      EventRegister.removeEventListener('pdf/open-page-annotation');
      EventRegister.removeEventListener('pdf/open-list');
      EventRegister.removeEventListener('notes/notes-updated');
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  const getAllNotes = () => {
    DataController.getAllNotes().then((notes) => {
      if (document) {
        let notesForDocument = notes.find((_note) => _note.documentId === document.id);

        if (notesForDocument) {
          notesForDocument.notes = _.orderBy(notesForDocument.notes, ['page', 'updated'], ['asc', 'desc']);

          setAllNotes(notesForDocument);
        } else {
          setAllNotes([]);
        }
      }
    });
  };

  const modalStyles = StyleSheet.create({
    modalDimensions: {
      height: Dimensions.get('window').height,
      width: isTablet() ? null : Dimensions.get('window').width,
    },
    modalWeb: {
      alignItems: undefined,
      justifyContent: undefined,
      width: Platform.OS === 'web' ? '100%' : Dimensions.get('window').width,
      height: Platform.OS === 'web' ? null : Dimensions.get('window').height,
      overflow: 'hidden',
      borderRadius: Platform.OS === 'web' ? 0 : 10,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      alignSelf: 'center',
      top: Platform.OS === 'web' ? 0 : 25,
      margin: 0,
    }
  });

  const shareNote = () => {
    const message =
      `${document['Document title']}\n\n${noteText}`;

    if (Platform.OS === 'web') {
      downloadText(message, false, page);
    } else {
      Share.share(
        {
          message,
          title: 'General Synod',
        },
        {
          dialogTitle: 'General Synod',
          excludedActivityTypes: [],
          subject: 'General Synod',
        }
      )
    }
  };

  const shareAllNotes = () => {
    if (allNotes.length === 0) {
      return;
    }

    const notesMapped = allNotes && allNotes.notes.map((item, index) => {
      const showHeader = index === 0 || allNotes.notes[index - 1].page !== item.page;

      return `${showHeader ? `Page ${item.page} Notes\n` : ''}${item.noteText}\n\n`
    });

    const message = `${document['Document title']}\n\n${notesMapped.join('')}`;

    if (Platform.OS === 'web') {
      downloadText(message, true);
    } else {
      Share.share(
        {
          message,
          title: 'General Synod',
        },
        {
          dialogTitle: 'General Synod',
          excludedActivityTypes: [],
          subject: 'General Synod',
        }
      )
    }
  };

  const discardNote = () => {
    if (noteId) {
      DataController.deleteNote(document, noteId).then(() => {
        resetModal();
      });
    } else {
      resetModal();
    }
  };

  const editNote = () => {
    DataController.editNote(document, noteId, noteText).then(() => {
      resetModal();
    });
  };

  const saveNote = () => {
    DataController.addNewNote(document, page, noteText).then((id) => {
      resetModal();
    });
  };

  const resetModal = () => {
    getAllNotes();
    setMode('list');
    setNoteId(null);
    setNoteText('');
    close();
    EventRegister.emit('documents/note-updated');
  };

  const closeModal = () => {
    if (noteText.length > 0) {
      if (noteId) {
        editNote();
      } else {
        saveNote();
      }
    } else {
      resetModal();
    }

  };

  const goToNote = (id) => {
    const selectedNote = allNotes && allNotes.notes.find((_note) => _note.id === id);

    if (selectedNote) {
      setMode('note');
      setNoteId(id);
      setNoteText(selectedNote.noteText);
    }
  };

  const goToPage = (pageNumber) => {
    EventRegister.emit('pdf/go-to-page', { pageNumber: pageNumber, });
  };

  if (Platform.OS === 'web') {
    return (
      <View>
        <RNModal
          isVisible={visible}
          onBackdropPress={closeModal}
          onBackButtonPress={closeModal}
          backdropTransitionInTiming={200}
          backdropTransitionOutTiming={0}
          style={modalStyles.modalWeb}>
          <View>
            <View style={[styles.modal, modalStyles.modalDimensions]}>
              <View style={styles.modalHeaderContainer}>
                {mode === 'list'
                  ? (
                    <NotesListHeader
                      screenProps={screenProps}
                      close={closeModal}
                      shareAllNotes={shareAllNotes}
                    />
                  ) : (
                    <NotesHeader
                      screenProps={screenProps}
                      close={closeModal}
                      empty={noteText === ''}
                      pageNumber={page}
                      shareNote={shareNote}
                      discardNote={discardNote}
                    />
                  )}
              </View>
              {mode === 'list'
                ? (
                  <NotesList
                    screenProps={screenProps}
                    document={document}
                    allNotes={allNotes}
                    goToNote={(id) => goToNote(id)}
                    goToPage={(pageNumber) => goToPage(pageNumber)}
                  />
                ) : (
                  <Note
                    modalVisible={visible}
                    screenProps={screenProps}
                    document={document}
                    noteText={noteText}
                    setNoteText={(input) => setNoteText(input)}
                  />
                )}
            </View>
          </View>
        </RNModal>
      </View>
    );
  }

  return (
    <View>
      <Modal
        visible={visible}
        onDismiss={() => Platform.OS === 'android' && closeModal()}
        onRequestClose={() => Platform.OS === 'android' && closeModal()}
        presentationStyle={isTablet() ? 'pageSheet' : 'pageSheet'}
        animationType='slide'>
        <View>
          <View style={[styles.modal, modalStyles.modalDimensions]}>
            <View style={styles.modalHeaderContainer}>
              {mode === 'list'
                ? (
                  <NotesListHeader
                    screenProps={screenProps}
                    close={closeModal}
                    shareAllNotes={shareAllNotes}
                  />
                ) : (
                  <NotesHeader
                    screenProps={screenProps}
                    close={closeModal}
                    empty={noteText === ''}
                    pageNumber={page}
                    shareNote={shareNote}
                    discardNote={discardNote}
                  />
                )}
            </View>
            {mode === 'list'
              ? (
                <NotesList
                  screenProps={screenProps}
                  document={document}
                  allNotes={allNotes}
                  goToNote={(id) => goToNote(id)}
                  goToPage={(pageNumber) => goToPage(pageNumber)}
                />
              ) : (
                <Note
                  modalVisible={visible}
                  screenProps={screenProps}
                  document={document}
                  noteText={noteText}
                  setNoteText={(input) => setNoteText(input)}
                />
              )}
          </View>
        </View>
      </Modal>
    </View>
  );
};

const NotesListHeader = ({ close, screenProps, shareAllNotes }) => {
  return (
    <>
      <TouchableOpacity
        style={styles.modalCloseButton}
        hitSlop={styles.modalCloseButtonHitSlop}
        onPress={() => close()}>
        <Text style={styles.modalCloseButtonText} allowFontScaling={false}>{'Close'}</Text>
      </TouchableOpacity>
      <Text style={styles.headerText} allowFontScaling={false}>
        {'Page Notes'}
      </Text>
      <View style={styles.modalHeaderButtons}>
        <TouchableOpacity style={styles.modalHeaderButton} onPress={() => shareAllNotes()}>
          <Image resizeMode={Platform.OS === 'web' || Platform.OS === 'android' ? 'center' : 'contain'} style={styles.icon} source={Platform.OS === 'ios' ? images.buttonShareIOs : images.Button_Share_Android} />
        </TouchableOpacity>
      </View>
    </>
  );
};

const NotesHeader = ({ pageNumber, close, empty, shareNote, discardNote, screenProps }) => {
  const discardOrClose = () => {
    if (empty) {
      discardNote();
    } else {
      close();
    }
  };

  return (
    <>
      <TouchableOpacity
        style={styles.modalCloseButton}
        hitSlop={styles.modalCloseButtonHitSlop}
        onPress={() => discardOrClose()}>
        <Text style={styles.modalCloseButtonText} allowFontScaling={false}>{empty ? 'Discard' : 'Close'}</Text>
      </TouchableOpacity>
      <Text style={styles.headerText} allowFontScaling={false}>
        {`Page ${pageNumber} Note`}
      </Text>
      <View style={styles.modalHeaderButtons}>
        <TouchableOpacity style={styles.modalHeaderButton} onPress={() => shareNote()}>
          <Image resizeMode={Platform.OS === 'web' || Platform.OS === 'android' ? 'center' : 'contain'} style={styles.icon} source={Platform.OS === 'ios' ? images.buttonShareIOs : images.Button_Share_Android} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.modalHeaderButton} onPress={() => discardNote()}>
          <Image resizeMode={Platform.OS === 'web' ? 'center' : 'contain'} style={styles.icon} source={images.buttonDelete} />
        </TouchableOpacity>
      </View>
    </>
  );
};

const NotesList = ({ document, allNotes, goToPage, goToNote, screenProps }) => {
  return (
    <ScrollView
      style={styles.modalScrollView}
      contentContainerStyle={styles.modalScrollViewContainer}
      stickyHeaderIndices={[0]}>
      <View style={[styles.noteHeader, { paddingStart: screenProps.safeAreaInsets.left, paddingEnd: screenProps.safeAreaInsets.right }]}>
        {document && document['GS Number'] && (
          <Text style={styles.gsNumber}>{document['GS Number']}</Text>
        )}
        {document && document['Document title'] && (
          <Text style={styles.documentTitle}>{document['Document title']}</Text>
        )}
      </View>
      {(allNotes && allNotes.notes !== undefined && allNotes.notes.length > 0) ? allNotes.notes.map((item, index) => {
        const showHeader = index === 0 || allNotes.notes[index - 1].page !== item.page;

        return (
          <View key={item.id}>
            {showHeader && (
              <>
                <View style={styles.pageSeparator} />
                <TouchableOpacity style={[styles.pageHeader, { paddingStart: screenProps.safeAreaInsets.left, paddingEnd: screenProps.safeAreaInsets.right }]} onPress={() => goToPage(item.page)}>
                  <Text style={styles.pageText}>{`Page ${item.page}`}</Text>
                  <Image style={styles.disclosure} source={images.Button_Disclosure_Purple} />
                </TouchableOpacity>
                <View style={styles.pageSeparator} />
              </>
            )}
            <TouchableOpacity activeOpacity={0.5} style={[styles.noteContainer, { paddingStart: screenProps.safeAreaInsets.left, paddingEnd: screenProps.safeAreaInsets.right }]} onPress={() => goToNote(item.id)}>
              <View style={styles.noteHeaderContainer}>
                <Text style={styles.noteDateText}>{format(item.updated, 'd MMMM yyyy')}</Text>
                <Text style={styles.noteEditText}>{'Edit'}</Text>
              </View>
              <Text numberOfLines={8} style={styles.noteText}>{item.noteText}</Text>
            </TouchableOpacity>
            <View style={styles.noteSeparator} />
          </View>
        );
      }) : (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text style={[styles.documentTitle, styles.noNotesText]}>{'No notes'}</Text>
        </View>
      )}
    </ScrollView>
  );
};

const Note = ({ modalVisible, document, noteText, setNoteText, screenProps }) => {
  const keyboardRef = useRef(null);

  useEffect(() => {
    try {
      if (modalVisible) {
        setTimeout(() => {
          keyboardRef.current?.focus();
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  }, [modalVisible]);

  return (
    <KeyboardAvoidingView style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', }} behavior="padding" enabled>
      <ScrollView
        style={styles.modalScrollView}
        contentContainerStyle={styles.modalScrollViewContainer}
        stickyHeaderIndices={[0]}>
        <View style={[styles.noteHeader, { paddingStart: screenProps.safeAreaInsets.left, paddingEnd: screenProps.safeAreaInsets.right }]}>
          {document && document['GS Number'] && (
            <Text style={styles.gsNumber}>{document['GS Number']}</Text>
          )}
          {document && document['Document title'] && (
            <Text style={styles.documentTitle} numberOfLines={1}>{document['Document title']}</Text>
          )}
        </View>
        {Platform.OS === 'ios' && (
          <InputAccessoryView nativeID={'doneToolbarID'}>
            <View style={{ alignItems: 'flex-end', backgroundColor: '#F3F2F7', borderTopWidth: 1, borderColor: '#AAA' }}>
              <TouchableOpacity onPress={() => Keyboard.dismiss()}>
                <Text style={{ padding: 13, color: '#382E73' }}>Done</Text>
              </TouchableOpacity>
            </View>
          </InputAccessoryView>
        )}
        <TextInput
          ref={keyboardRef}
          multiline
          value={noteText}
          ref={keyboardRef}
          onChangeText={(input) => setNoteText(input)}
          inputAccessoryViewID={'doneToolbarID'}
          placeholder="Add note..."
          placeholderTextColor="#97A1A7"
          style={{
            width: '100%',
            height: '100%',
            fontFamily: 'Inter-Regular',
            fontSize: 17,
            fontWeight: 'normal',
            fontStyle: 'normal',
            lineHeight: 26,
            letterSpacing: -0.3,
            color: '#212529',
            paddingVertical: 16,
            paddingStart: screenProps.safeAreaInsets.left + 16,
            paddingEnd: screenProps.safeAreaInsets.right + 16,
            // backgroundColor: '#AF2',
            textAlignVertical: 'top',
            alignSelf: 'flex-start',
          }}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  modal: {
    // marginTop: isTablet() ? 30 : 0,
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  modalHeaderContainer: {
    height: Platform.OS === 'web' ? 44 : 48,
    backgroundColor: '#382E73',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopStartRadius: Platform.OS === 'web' || Platform.OS === 'android' ? 0 : 10,
    borderTopEndRadius: Platform.OS === 'web' || Platform.OS === 'android' ? 0 : 10,
  },
  modalHeaderContainerWeb: {
    height: 48,
    backgroundColor: '#382E73',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopStartRadius: Platform.OS === 'web' ? 0 : 10,
    borderTopEndRadius: Platform.OS === 'web' ? 0 : 10,
  },
  modalCloseButton: {
    position: 'absolute',
    left: Platform.OS === 'web' ? null : 16,
    right: Platform.OS === 'web' ? 16 : null,
  },
  modalCloseButtonWeb: {
    position: 'absolute',
    left: 16,
  },
  modalHeaderButtons: {
    position: 'absolute',
    left: Platform.OS === 'web' ? 0 : null,
    right: Platform.OS === 'web' ? null : 0,
    flexDirection: 'row',
    alignSelf: 'center'
  },
  modalHeaderButton: {
    width: 44,
    height: 44,
    marginEnd: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalCloseButtonHitSlop: {
    top: 12,
    bottom: 12,
    left: 10,
    right: 10,
  },
  modalCloseButtonText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#F1F3F5',
  },
  headerText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#F1F3F5',
    textAlign: 'center',
    alignSelf: 'center'
  },
  modalScrollView: {
    backgroundColor: '#FFFFFF',
  },
  modalScrollViewContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    paddingBottom: isIphoneX() ? 100 : Platform.OS === 'web' ? 0 : 44,
    // width: Platform.OS === 'web' ? '66%' : '100%',
    width: '100%',
    alignSelf: 'center',
    backgroundColor: '#FFFFFF',
  },
  modalScrollViewContainerWeb: {
    flexGrow: 1,
    flexDirection: 'column',
    marginHorizontal: 20,
    paddingBottom: Platform.OS === 'ios' ? 60 : 16,
    width: '66%',
    paddingTop: 16,
    alignSelf: 'center',
  },
  signInText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 15,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.2,
    color: '#7F8A93',
  },
  userText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#212529',
    marginTop: 4,
  },
  button: {
    width: '100%',
    maxWidth: 354,
    height: 44,
    backgroundColor: '#A08BC1',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    alignSelf: 'center',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Inter',
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#F1F3F5'
  },
  noteHeader: {
    minHeight: 82,
    borderBottomWidth: 1,
    borderBottomColor: '#CFD3D6',
    justifyContent: 'center',
    paddingVertical: 16,
    backgroundColor: '#FFFFFF'
  },
  gsNumber: {
    fontFamily: 'Inter-Regular',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.3,
    color: '#7F8A93',
    marginHorizontal: 16,
  },
  documentTitle: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'ios' ? '600' : 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#212529',
    marginHorizontal: 16,
  },
  noNotesText: {
    alignSelf: 'center',
  },
  noteContainer: {
    padding: 16,
  },
  noteSeparator: {
    height: 1,
    backgroundColor: '#CFD3D6'
  },
  noteHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  noteDateText: {
    fontFamily: 'Inter-Regular',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.3,
    color: '#7F8A93',
    marginStart: 16,
  },
  noteEditText: {
    fontFamily: 'Inter-Regular',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.3,
    color: '#382E73',
    marginEnd: 16,
  },
  noteText: {
    fontFamily: 'Inter-Regular',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.3,
    color: '#212529',
    marginHorizontal: 16,
  },
  pageHeader: {
    height: 44,
    backgroundColor: '#EBEDEE',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageText: {
    fontFamily: 'Inter-Bold',
    fontSize: 13,
    fontWeight: Platform.OS === 'ios' ? 'bold' : 'normal',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    color: '#212529',
    marginStart: 16,
  },
  disclosure: {
    width: 44,
    height: 44,
    marginEnd: 4,
  },
  pageSeparator: {
    height: 1,
    backgroundColor: '#97A1A7',
  },
  icon: {
    height: 44,
    width: 44,
  },
});

export default PDFAnnotationModal;