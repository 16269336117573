import firebase from 'firebase/app';
import 'firebase/analytics';

const FirebaseService = {
  logEvent(event, params) {
    try {
      firebase.analytics().logEvent(event, params);
    } catch (error) {
      console.log(error);
    }
  }
};

export default FirebaseService;
