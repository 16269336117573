import React, { useEffect, useState } from 'react';
import ModalWeb from 'react-native-modal';
import { Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";

const UpdatesModal = ({ visible, close, title, message, buttons }) => {
  return (
    <ModalWeb
      isVisible={visible}
      onBackdropPress={close}
      backdropOpacity={0.5}
      backdropColor={'#000000'}
      animationIn="fadeIn"
      animationOut="fadeOut"
      backdropTransitionOutTiming={0}
      hideModalContentWhileAnimating={true}
      style={{
        borderRadius: 10,
        alignSelf: 'center',
        justifyContent: 'center',
      }}>
      <View style={styles.deleteModalContainer}>
        <View style={styles.deleteModalTitleContainer}>
          <View style={styles.deleteBlockTextContainer}>
            <Text style={styles.deleteBlockText}>
              {title}
            </Text>
          </View>
          <Text style={styles.confirmDeleteText}>
            {message}
          </Text>
          <View style={styles.deleteModalSeparator} />
          <View>
            {buttons.map((button, index) => (
              <View key={button.text}>
                <TouchableOpacity
                  style={styles.deleteModalButton}
                  onPress={() => button.onPress()}>
                  <Text style={styles.deleteButtonText}>{button.text}</Text>
                </TouchableOpacity>
                {index !== buttons.length - 1 && <View style={styles.deleteModalSeparatorNoMargin} />}
              </View>
            ))}
          </View>
        </View>
      </View>
    </ModalWeb>
  );
};

const styles = StyleSheet.create({
  deleteModal: {
    borderRadius: 8,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  deleteModalContainer: {
    alignSelf: 'center',
    borderRadius: 13,
    overflow: 'hidden',
    width: 300,
  },
  deleteModalTitleContainer: {
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  deleteBlockTextContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    alignSelf: 'center',
    overflow: 'hidden',
    padding: 24,
    paddingBottom: 4,
  },
  deleteBlockText: {
    fontFamily: 'Inter-SemiBold',
    alignSelf: 'center',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    color: '#000000',
    textAlign: 'center',
  },
  confirmDeleteText: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    fontWeight: '400',
    paddingBottom: 12,
    paddingHorizontal: 24,
    letterSpacing: -0.1,
    lineHeight: 26,
    color: '#000000',
    textAlign: 'center',
  },
  deleteModalSeparator: {
    height: 1,
    backgroundColor: '#D9D9D9',
    marginTop: 4,
  },
  deleteModalSeparatorNoMargin: {
    height: 1,
    backgroundColor: '#D9D9D9',
  },
  row: {
    flexDirection: 'row',
  },
  deleteModalButton: {
    flex: 1,
    height: 44,
    justifyContent: 'center',
  },
  deleteButtonText: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    fontWeight: '400',
    padding: 12,
    paddingHorizontal: 12,
    letterSpacing: -0.1,
    lineHeight: 26,
    color: '#007aff',
    textAlign: 'center',
  },
});

export default UpdatesModal;
