/* eslint-disable global-require */
const images = {
  Button_Account: require('./images/Button_Account.png'),
  Button_Bookmark_Active: require('./images/Button_Bookmark_Active.png'),
  Button_Bookmark_Inactive: require('./images/Button_Bookmark_Inactive.png'),
  Button_Contact_Active: require('./images/Button_Contact_Active.png'),
  Button_Contact_Inactive: require('./images/Button_Contact_Inactive.png'),
  Button_Delete: require('./images/Button_Delete.png'),
  Button_Disclosure_Collapse: require('./images/Button_Disclosure_Collapse.png'),
  Button_Disclosure_Expand: require('./images/Button_Disclosure_Expand.png'),
  Button_Disclosure_Purple: require('./images/Button_Disclosure_Purple.png'),
  Button_Disclosure_Right: require('./images/Button_Disclosure_Right.png'),
  Button_Disclosure_White_Down: require('./images/Button_Disclosure_White_Down.png'),
  Button_Disclosure_White_Left: require('./images/Button_Disclosure_White_Left.png'),
  Button_Documents_Active: require('./images/Button_Documents_Active.png'),
  Button_Documents_Inactive: require('./images/Button_Documents_Inactive.png'),
  Button_Info: require('./images/Button_Info.png'),
  Button_More: require('./images/Button_More.png'),
  Button_MySynod_Active: require('./images/Button_MySynod_Active.png'),
  Button_MySynod_Inactive: require('./images/Button_MySynod_Inactive.png'),
  Button_Note_Add: require('./images/Button_Note_Add.png'),
  Button_Note_Edit: require('./images/Button_Note_Edit.png'),
  Button_PageSearch_Active: require('./images/Button_PageSearch_Active.png'),
  Button_PageSearch_Inactive: require('./images/Button_PageSearch_Inactive.png'),
  Button_Paging: require('./images/Button_Paging.png'),
  Button_Remove: require('./images/Button_Remove.png'),
  Button_Scrolling: require('./images/Button_Scrolling.png'),
  Button_Share_Android: require('./images/Button_Share_Android.png'),
  Button_Share_iOS: require('./images/Button_Share_iOS.png'),
  Button_Timetable_Active: require('./images/Button_Timetable_Active.png'),
  Button_Timetable_Inactive: require('./images/Button_Timetable_Inactive.png'),
  Button_Updates_Active: require('./images/Button_Updates_Active.png'),
  Button_Updates_Base: require('./images/Button_Updates_Base.png'),
  Button_Updates_Inactive: require('./images/Button_Updates_Inactive.png'),
  Document_Active: require('./images/Document_Active.png'),
  Document_Active_Note: require('./images/Document_Active_Note.png'),
  Document_Inactive: require('./images/Document_Inactive.png'),
  Document_Inactive_Note: require('./images/Document_Inactive_Note.png'),
  Icon_Document_Bookmark: require('./images/Icon_Document_Bookmark.png'),
  Icon_Document_Note_Active: require('./images/Icon_Document_Note_Active.png'),
  Icon_Document_Note_Inactive: require('./images/Icon_Document_Note_Inactive.png'),
  MySynod_Folder: require('./images/MySynod_Folder.png'),
  buttonCloseGrey: require('./images/buttonCloseGrey.png'),
  buttonDelete: require('./images/buttonDelete.png'),
  buttonInfo: require('./images/buttonInfo.png'),
  buttonLogo1Inactive: require('./images/buttonLogo1Inactive.png'),
  buttonShareIOs: require('./images/buttonShareIOs.png'),
  close: require('./images/close.png'),
  loginLogo: require('./images/loginLogo.png'),
  logoGs: require('./images/logoGs.png'),
  note_delete: require('./images/note_delete.png'),
  tool_search: require('./images/tool_search.png'),
  web_icon: require('./images/web_icon.png'),
};

export default images;
