/**
 * Synod
 * @flow
 */
'use strict';
import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
  Image,
  TextInput,
  FlatList,
  SectionList,
  KeyboardAvoidingView,
  Keyboard,
  Alert,
  Platform,
} from 'react-native';

import Share from 'react-native-share';

import DataController from '../controllers/DataController';
import moment from 'moment';
import images from '../resources/images';

export default class EditNoteScreen extends Component {

  static navigationOptions = ({ navigation }) => {

    var shareIconSource = images.Button_Share_iOS;

    if (Platform.OS === 'android') {
      shareIconSource = images.Button_Share_Android;
    }

    var closeButtonText = navigation.getParam('closeButtonText', 'Close');

    var rightButtonsLayout = (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>

        <TouchableOpacity onPress={navigation.getParam('shareButtonTapped')}>
          <Image source={shareIconSource} style={{ marginHorizontal: 10, height: 24, width: 24 }} resizeMode='contain' />
        </TouchableOpacity>

        <TouchableOpacity onPress={navigation.getParam('deletePrompt')}>
          <Image source={images.note_delete} style={{ marginHorizontal: 10, tintColor: '#FFF', height: 24, width: 24 }} resizeMode='contain' />
        </TouchableOpacity>

      </View>
    );

    return {
      title: 'Note',
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
      },
      headerTintColor: '#fff',
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontWeight: '500'
      },
      headerLeft: (
        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', height: 30 }} onPress={navigation.getParam('closeButtonTapped')}>
          <View style={{ marginHorizontal: 16 }}>
            <Text style={{ color: '#FFF', fontSize: 17 }}>{closeButtonText}</Text>
          </View>
        </TouchableOpacity>
      ),
      headerRight: rightButtonsLayout,
    };
  };

  state: {
    note: ?Object;
    noteText: string;
    originalNoteText: string;
    pointerEvents: string;
    closeButtonText: string;
  };

  constructor(props) {
    super(props);

    var document = null;

    if (this.props.document != null) {
      document = this.props.document;
    } else {
      document = this.props.navigation.getParam('document', null);
    }

    this.state = {
      document: document,
      note: null,
      noteText: '',
      originalNoteText: '',
      pointerEvents: 'none',
      closeButtonText: 'Close',
    };
  }

  componentDidMount() {

    var that = this;

    if (this.props.navigation != null) {
      setTimeout(() => {
        that.props.navigation.setParams({ closeButtonTapped: that.closeButtonTapped.bind(that), shareButtonTapped: that.shareButtonTapped.bind(that), deletePrompt: that.deletePrompt.bind(that) });
      }, 100);
    }

    this.loadNote();
  }

  async loadNote() {

    var note = await DataController.getNoteForDocument(this.state.document);

    var noteText = '';

    if (note !== null) {

      noteText = note.noteText;
      this.setState({ note: note })
    }

    this.setState({ noteText: noteText, originalNoteText: noteText })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {


  }

  noteTextDidChange(noteText: string) {

    this.setState({ noteText: noteText });

    var closeButtonText = 'Close';

    if (noteText !== this.state.originalNoteText) {
      closeButtonText = 'Save';
    }

    if (this.props.navigation != null) {
      this.props.navigation.setParams({ closeButtonText: closeButtonText });
    } else {
      this.setState({ closeButtonText: closeButtonText });
    }
  }

  closeButtonTapped() {

    if (this.state.noteText !== this.state.originalNoteText) {

      this.save();

    } else {

      if (this.state.noteText === '') {
        this.delete();
      } else {
        this.close();
      }
    }

    // if (this.state.noteText !== this.state.originalNoteText) {

    //   Alert.alert(
    //     'Discard changes?',
    //     'Are you sure you want to discard your changes?',
    //     [
    //       {text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel'},
    //       {text: 'OK', onPress: () => {
    //         console.log('OK Pressed')

    //         this.close();

    //       }},
    //     ]
    //   )
    // } else {

    //   var that = this;
    //   setTimeout(() => {
    //     that.close()        
    //   }, 10);
    // }
  }

  close() {

    if (this.props.navigation != null) {
      this.props.navigation.goBack();
    } else {
      this.props.closeEditNote();
    }
  }

  async save() {

    var updatedDate = new Date();

    await DataController.saveNoteForDocument(this.state.document, this.state.noteText);

    this.setState({ originalNoteText: this.state.noteText });

    if (this.props.navigation != null) {
      this.props.navigation.setParams({ closeButtonText: 'Close' });
    } else {
      this.setState({ closeButtonText: 'Close' });
    }

    this.blurEditor();
  }

  deletePrompt() {

    Alert.alert(
      'Delete note?',
      'Are you sure you want to delete this note?',
      [
        { text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel' },
        {
          text: 'OK', onPress: () => {
            console.log('OK Pressed')

            this.delete();

          }
        },
      ]
    )
  }

  async delete() {

    await DataController.deleteNoteForDocument(this.state.document);

    this.close();
  }

  shareButtonTapped() {

    var sharingText = this.state.noteText;
    var titleText = "Note for " + this.state.document['Document title'];

    let shareOptions = {
      title: "General Synod - " + titleText,
      message: titleText + ": " + sharingText,
      subject: "General Synod - " + titleText //  for email
    };

    Share.open(shareOptions);
  }

  focusEditor() {

    console.log('focussing');
    this.setState({ pointerEvents: 'auto' });
    this._textInput.focus();
    setTimeout(() => {
      this._scrollView.scrollToEnd({ animated: true })
    }, 100);
  }

  blurEditor() {
    this.setState({ pointerEvents: 'none' });
    this._textInput.blur();
  }

  render() {

    var textColor = '#000';
    var lightTextColor = '#757575'
    var backgroundColor = '#F3F2F7';
    var contentBackgroundColor = '#FFF';
    var lineColor = '#DDD';

    var shareIconSource = images.Button_Share_iOS;

    if (Platform.OS === 'android') {
      shareIconSource = images.Button_Share_Android;
    }

    var document = this.state.document;

    var gsNumber = '';
    if (document['GS Number'] != null) {
      gsNumber = document['GS Number'];
    }

    var screenPaddingLeft = 0;
    var screenPaddingRight = 0;
    var screenPaddingBottom = 0;

    if (this.props.navigation != null) {
      screenPaddingLeft = this.props.screenProps.safeAreaInsets.left;
      screenPaddingRight = this.props.screenProps.safeAreaInsets.right;
      screenPaddingRight = this.props.screenProps.safeAreaInsets.bottom;
    }

    var topBar = null;

    if (this.props.navigation == null) {

      var bookmarkIconSource = images.Button_Bookmark_Inactive;

      if (this.state.isBookmarked) {
        bookmarkIconSource = images.Button_Bookmark_Active;
      }

      var noteIconSource = images.Button_Note_Add;

      if (this.state.hasNote) {
        noteIconSource = images.Button_Note_Edit;
      }

      var pagingIconSource = images.Button_Scrolling;

      if (this.state.pagingEnabled) {
        pagingIconSource = images.Button_Paging;
      }

      var rightButtonsLayout = (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity onPress={this.shareButtonTapped.bind(this)}>
            <Image source={shareIconSource} style={{ marginHorizontal: 10, height: 24, width: 24 }} resizeMode='contain' />
          </TouchableOpacity>

          <TouchableOpacity onPress={this.deletePrompt.bind(this)}>
            <Image source={images.note_delete} style={{ marginHorizontal: 10, tintColor: '#FFF', height: 24, width: 24 }} resizeMode='contain' />
          </TouchableOpacity>

        </View>
      );

      topBar = (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 64, backgroundColor: '#382E73', paddingTop: 20 }}>

          <Text style={{ position: 'absolute', left: 0, right: 0, top: 32, textAlign: 'center', fontSize: 17, fontWeight: '500', color: '#FFF' }}>Note</Text>

          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', height: 30 }} onPress={this.closeButtonTapped.bind(this)}>
            <View style={{ marginHorizontal: 16 }}>
              <Text style={{ color: '#FFF', fontSize: 17 }}>{this.state.closeButtonText}</Text>
            </View>
          </TouchableOpacity>

          {rightButtonsLayout}

        </View>
      )
    }

    var contentLayout = (

      <View style={{ flex: 1, backgroundColor: backgroundColor, paddingLeft: screenPaddingLeft, paddingRight: screenPaddingRight }}>

        <View style={{ paddingHorizontal: 8, paddingVertical: 14, backgroundColor: '#FFF' }}>
          <Text style={{ fontSize: 14, color: lightTextColor }}>{gsNumber}</Text>
          <Text style={{ marginTop: 6, fontSize: 16, fontWeight: '500', color: textColor }} numberOfLines={1}>{document['Document title']}</Text>
        </View>

        <View style={{ height: 1, backgroundColor: '#D5D9DB' }}></View>

        <View style={{ flex: 1, backgroundColor: contentBackgroundColor, margin: 10, shadowColor: '#000', shadowOffset: { width: 0, height: 1 }, shadowRadius: 2, shadowOpacity: 0.2 }}>

          <ScrollView ref={(c) => this._scrollView = c} style={{ flex: 1 }} contentContainerStyle={{ flex: 1 }}>
            <TouchableOpacity activeOpacity={1} style={{ flex: 1 }} onPress={this.focusEditor.bind(this)}>
              <TextInput
                ref={(c) => this._textInput = c}
                style={{ flex: 1, fontSize: 15, marginHorizontal: 8, marginVertical: 8, color: textColor }}
                onChangeText={(text) => this.noteTextDidChange(text)}
                value={this.state.noteText}
                pointerEvents={this.state.pointerEvents}
                onBlur={this.blurEditor.bind(this)}
                placeholder={'Note...'}
                placeholderTextColor={'#999'}
                textAlignVertical={'top'}
                multiline={true}
                returnKeyType={'default'}
              />
            </TouchableOpacity>
          </ScrollView>

        </View>

      </View>
    );


    if (Platform.OS === 'ios') {

      var topPadding = 62;

      if (this.props.navigation == null) {
        topPadding = 0;
      }

      return (
        <View style={{ flex: 1 }}>

          {topBar}

          <KeyboardAvoidingView behavior={'padding'} style={{ flex: 1 }} keyboardVerticalOffset={topPadding + screenPaddingBottom}>

            {contentLayout}

          </KeyboardAvoidingView>

        </View>
      )

    } else {

      return (
        <View style={{ flex: 1 }}>
          {contentLayout}
        </View>
      )
    }
  }
}


const styles = StyleSheet.create({
  closeButton: {
    width: 44,
    height: 44,
    position: 'absolute',
    left: 10,
    top: 10,
  },
  menuBoxContainer: {
    marginHorizontal: 0,
  },
  menuBox: {
    backgroundColor: 'rgba(255,255,255,0.9)',
    borderWidth: 1,
    borderColor: '#FFF',
    borderRadius: 5,
    marginBottom: 8,
    padding: 14,
  },
})