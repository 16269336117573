import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Alert,
  Image,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { EventRegister } from 'react-native-event-listeners';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthenticationController from '../controllers/AuthenticationController';
import ModalWeb from 'react-native-modal';
import images from '../resources/images';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import DatabaseService from '../controllers/DatabaseService';

const useProxy = true;

//'https://synodapp.churchofengland.org';

//uat server config 
const redirectUri = Platform.OS === 'web' && __DEV__ ?  window.location.href : 'https://webapp-uat.generalsynodapp.com';

//Prod server config
//const redirectUri = Platform.OS === 'web' && __DEV__ ?  window.location.href : 'https://webapp.generalsynodapp.com';

const endpointProd = 'https://churchofengland2--uat.sandbox.my.site.com/synod';


const LoginScreen = ({ navigation }) => {
  const [signingIn, setSigningIn] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [request, response, promptAsync] = AuthSession.useAuthRequest(
    {
      responseType: AuthSession.ResponseType.Token,
      clientId: '3MVG9tklOJf0sawXpSvDFKSL9sb7r4RWaOVvIruXkmz7Dgt526VSg.JzGKlBpGhYBR3CIadczeD3uB0cE4SNK',
      redirectUri,
      prompt: 'login',
      usePKCE: false,
      state: 'state'
    },
    {
      authorizationEndpoint: `${endpointProd}/services/oauth2/authorize?client_id=3MVG9tklOJf0sawXpSvDFKSL9sb7r4RWaOVvIruXkmz7Dgt526VSg.JzGKlBpGhYBR3CIadczeD3uB0cE4SNK&redirect_uri=${redirectUri}&response_type=token`,
    }

    // https://churchofengland.force.com/synod/services/oauth2/authorize?client_id=3MVG9xqN3LZmHU7nPDCp0_1UhD9KwB098kYFfx9QIqNnAN_v_mLN95dmNLu6TRDkjyIGqtjn3amAJP2PCRE4U&redirect_uri=https://synodapp.churchofengland.org&response_type=token
  )

  useEffect(() => {
    const checkIfSynodMember = async (result) => {
      console.log("checkIfSynodMember",result)
      const user = {
        accessToken: result.access_token,
        accessTokenExpirationDate: '',
        authorizeAdditionalParameters: {
          sfdc_community_id: result.sfdc_community_id,
          sfdc_community_url: result.sfdc_community_url,
        },
        idToken: result.id_token,
        refreshToken: result.refresh_token,
        tokenAdditionalParameters: {
          id: result.id,
          instance_url: result.instance_url,
          issued_at: result.issued_at,
          sfdc_community_id: result.sfdc_community_id,
          sfdc_community_url: result.sfdc_community_url,
          signature: result.signature,
        },
        tokenType: result.token_type
      };

      const refreshToken = result.refresh_token;

      __DEV__ && console.log("userobj",user);

      AuthenticationController.getUserData(user, user.accessToken).then(async userInfo => {
        AuthenticationController.checkIfSynodMember(user.accessToken, userInfo.user_id).then(async synodMember => {
          __DEV__ && console.log("synodMember",synodMember);
          if (synodMember) {
            DatabaseService.getNotes(userInfo.user_id).then(async (body) => {
              __DEV__ && console.log(body);

              if (body.Notes) {
                await AsyncStorage.setItem('DocumentNotes', body.Notes);
              }

              await AsyncStorage.setItem('user', JSON.stringify(user));
              await AsyncStorage.setItem('user', JSON.stringify(user));
              await AsyncStorage.setItem('refreshToken', user.refreshToken);
              await AsyncStorage.setItem('userInfo', JSON.stringify(userInfo));
              EventRegister.emit('user/login', user);
              EventRegister.emit('user/user-info', userInfo);
            }, async (error) => {
              await AsyncStorage.setItem('user', JSON.stringify(user));
              await AsyncStorage.setItem('user', JSON.stringify(user));
              await AsyncStorage.setItem('refreshToken', user.refreshToken);
              await AsyncStorage.setItem('userInfo', JSON.stringify(userInfo));
              EventRegister.emit('user/login', user);
              EventRegister.emit('user/user-info', userInfo);
            });
          } else {
            setSigningIn(false);
            displayAlert();
          }
        }).catch((error) => displayAlert());
      }).catch((error) => displayAlert());
    };

    if (Platform.OS === 'web') {
      WebBrowser.maybeCompleteAuthSession();

      if (response && response.type === 'success') {
        const params = response.params;

        checkIfSynodMember(params)
          .then(() => { })
          .catch((error) => {
            setSigningIn(false);
          });
      } else {
        setSigningIn(false);
      }
    }
  }, [request, response]);

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  const webLogin = async () => {
    setSigningIn(true);
    console.log("login ur",`${endpointProd}/services/oauth2/authorize?client_id=3MVG9tklOJf0sawXpSvDFKSL9sb7r4RWaOVvIruXkmz7Dgt526VSg.JzGKlBpGhYBR3CIadczeD3uB0cE4SNK&redirect_uri=${redirectUri}&response_type=token&state=state`)

    promptAsync({
      showTitle: false,
      windowName: 'Synod Login',
      url: `${endpointProd}/services/oauth2/authorize?client_id=3MVG9tklOJf0sawXpSvDFKSL9sb7r4RWaOVvIruXkmz7Dgt526VSg.JzGKlBpGhYBR3CIadczeD3uB0cE4SNK&redirect_uri=${redirectUri}&response_type=token&state=state`
    });
  };

  const buttonPressed = async () => {
    setSigningIn(true);

    AuthenticationController.authorizeUser().then(async user => {
      if (Platform.OS === 'web') {
        await AsyncStorage.setItem('user', JSON.stringify(user));
        EventRegister.emit('user/login', user);
        return;
      }

      if (user) {
        __DEV__ && console.log('USER', user);
        AuthenticationController.getUserData(user, user.accessToken).then(async userInfo => {
          __DEV__ && console.log('USERINFO', user, userInfo,);
          AuthenticationController.checkIfSynodMember(user.accessToken, userInfo.user_id).then(async synodMember => {
            __DEV__ && console.log('USERINFO', user, userInfo, synodMember);
            if (synodMember) {
              DatabaseService.getNotes(userInfo.user_id).then(async (body) => {
                if (body.Notes) {
                  await AsyncStorage.setItem('DocumentNotes', body.Notes);
                }

                await AsyncStorage.setItem('user', JSON.stringify(user));
                await AsyncStorage.setItem('refreshToken', user.refreshToken);
                await AsyncStorage.setItem('userInfo', JSON.stringify(userInfo));
                EventRegister.emit('user/login', user);
                EventRegister.emit('user/user-info', userInfo);
                // setSigningIn(false);
              }, async (error) => {
                await AsyncStorage.setItem('user', JSON.stringify(user));
                await AsyncStorage.setItem('refreshToken', user.refreshToken);
                await AsyncStorage.setItem('userInfo', JSON.stringify(userInfo));
                EventRegister.emit('user/login', user);
                EventRegister.emit('user/user-info', userInfo);
                // setSigningIn(false);
              });
            } else {
              setSigningIn(false);
              displayAlert();
            }

            setSigningIn(false);
          });
        });
      } else {
        setSigningIn(false);
      }
    }).catch(error => {
      setSigningIn(false);
    });
  };

  const openEmail = () => {
    const email = 'synod@churchofengland.org';
    const mailTo = `mailto:${email}?subject=Synod App Login Issues`;

    try {
      if (Platform.OS === 'web') {
        window.open(mailTo, '_blank');
      } else {
        Linking.openURL(mailTo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayAlert = () => {
    if (Platform.OS === 'web') {
      setSigningIn(false);
      setAlertVisible(true);
    } else {
      const buttons = [
        { text: 'Dismiss', onPress: () => null },
        { text: 'Contact', onPress: () => openEmail(), style: 'default' },
      ];
      Alert.alert(
        'This email address is not associated with a General Synod member account',
        'If you believe this to be a mistake, please contact us',
        Platform.OS === 'android' ? buttons.reverse() : buttons,
      );
    }
  }

  return (
    <ScrollView
      bounces={false}
      style={styles.scrollView}
      contentContainerStyle={styles.scrollViewContainer}>
      <View style={styles.container}>
        {Platform.OS === 'web' && (
          <ContactModal
            visible={alertVisible}
            close={() => setAlertVisible(false)}
            openEmail={() => openEmail()}
          />
        )}
        <Image style={styles.logo} source={images.loginLogo} />
        <Text style={styles.text}>
          {
            'This app is for General Synod members. Sign in to your account using the button below in order to access timetables, documents and your notes across devices.\n\nYou can also reset your password using the button below, if you have forgotten it.'
          }
        </Text>
        {/* <TouchableOpacity style={styles.button} onPress={() => Platform.OS === 'web' ? displayAlert() : displayAlert()}> */}
        <TouchableOpacity style={styles.button} onPress={() => Platform.OS === 'web' ? webLogin() : buttonPressed()}>
          {signingIn ? (
            <ActivityIndicator color="#F1F3F5" />
          ) : (
            <Text
              style={styles.buttonText}
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              allowFontScaling={false}>
              {'Sign in or Reset your password'}
            </Text>
          )}
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const ContactModal = ({ visible, close, openEmail }) => {
  return (
    <ModalWeb
      isVisible={visible}
      onBackdropPress={close}
      backdropOpacity={0.5}
      backdropColor={'#000000'}
      animationIn="fadeIn"
      animationOut="fadeOut"
      backdropTransitionOutTiming={0}
      hideModalContentWhileAnimating={true}
      style={{
        borderRadius: 10,
        alignSelf: 'center',
        justifyContent: 'center',
      }}>
      <View style={styles.deleteModalContainer}>
        <View style={styles.deleteModalTitleContainer}>
          <View style={styles.deleteBlockTextContainer}>
            <Text style={styles.deleteBlockText}>
              {'This email address is not associated with a General Synod member account'}
            </Text>
          </View>
          <Text style={styles.confirmDeleteText}>
            {'If you believe this to be a mistake, please contact us'}
          </Text>
          <View style={styles.deleteModalSeparator} />
          <View style={styles.row}>
            <TouchableOpacity
              style={styles.deleteModalButton}
              onPress={openEmail}>
              <Text style={styles.deleteButtonText}>{'Contact'}</Text>
            </TouchableOpacity>
            <View style={styles.deleteModalSeparatorNoMargin} />
            <TouchableOpacity
              style={styles.deleteModalButton}
              onPress={close}>
              <Text style={styles.deleteButtonText}>{'Dismiss'}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ModalWeb>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: '#F3F2F7',
  },
  scrollViewContainer: {
    flex: 1,
    backgroundColor: '#F3F2F7',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  logo: {
    width: 250,
    height: 125,
    marginBottom: 16,
  },
  text: {
    fontFamily: 'Inter-Regular',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.3,
    textAlign: 'center',
    color: '#212529',
    paddingHorizontal: 30,
    maxWidth: 394,
  },
  button: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 43,
    borderRadius: 4,
    backgroundColor: '#A08BC1',
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    marginHorizontal: 30,
    marginTop: 24,
    maxWidth: 354,
    elevation: 8,
  },
  buttonText: {
    fontFamily: 'Inter-Bold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#F1F3F5',
  },
  deleteModal: {
    borderRadius: 8,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  deleteModalContainer: {
    alignSelf: 'center',
    borderRadius: 13,
    overflow: 'hidden',
    width: 300,
  },
  deleteModalTitleContainer: {
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  deleteBlockTextContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    alignSelf: 'center',
    overflow: 'hidden',
    padding: 24,
    paddingBottom: 4,
  },
  deleteBlockText: {
    fontFamily: 'Inter-SemiBold',
    alignSelf: 'center',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    color: '#000000',
    textAlign: 'center',
  },
  confirmDeleteText: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    fontWeight: '400',
    paddingBottom: 12,
    paddingHorizontal: 24,
    letterSpacing: -0.1,
    lineHeight: 26,
    color: '#000000',
    textAlign: 'center',
  },
  deleteModalSeparator: {
    height: 1,
    backgroundColor: '#D9D9D9',
    marginTop: 4,
  },
  deleteModalSeparatorNoMargin: {
    width: 1,
    backgroundColor: '#D9D9D9',
  },
  row: {
    flexDirection: 'row',
  },
  deleteModalButton: {
    flex: 1,
    height: 44,
    justifyContent: 'center',
  },
  deleteButtonText: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    fontWeight: '400',
    padding: 12,
    paddingHorizontal: 12,
    letterSpacing: -0.1,
    lineHeight: 26,
    color: '#007aff',
    textAlign: 'center',
  },
});

export default LoginScreen;
