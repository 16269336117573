/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';

type Props = {

}

type State = {

}

export default class CheckBoxGroup extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }

  render() {

    var optionsLayout = [];

    for (var optionIndex = 0; optionIndex < this.props.options.length; optionIndex++) {

      var option = this.props.options[optionIndex];

      var filledInLayout = null;

      if (option === this.props.selectedOption) {
        filledInLayout = (
          <View style={{ width: 26, height: 26, backgroundColor: '#A08BC1', borderRadius: 13 }} />
        )
      }

      var anOptionLayout = (
        <View key={option} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.text}>{option}</Text>
          <TouchableOpacity activeOpacity={0.5} style={{ flex: 1 }} onPress={this.props.optionSelected.bind(this, option)}>
            <View style={{ width: 32, height: 32, backgroundColor: '#FFF', borderWidth: 1, borderColor: '#A08BC1', borderRadius: 16, justifyContent: 'center', alignItems: 'center' }}>
              {filledInLayout}
            </View>
          </TouchableOpacity>
        </View>
      );

      optionsLayout.push(anOptionLayout);
    }

    return (
      <View style={{ flexDirection: 'row', paddingVertical: 16 }}>
        {optionsLayout}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 18,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    letterSpacing: -0.2,
    color: '#3C3E4D',
    marginEnd: 10,
  }
});