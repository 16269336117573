/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Dimensions,
  ScrollView,
  FlatList,
  TextInput,
  KeyboardAvoidingView,
} from 'react-native';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

// import { NavigationEvents } from 'react-navigation';

import Fuse from 'fuse.js';

import DataController from '../controllers/DataController';
import images from '../resources/images';
import { EventRegister } from 'react-native-event-listeners';
var _ = require('lodash');

type Props = {
  title: string;
  documents: Object[];
}

type State = {
  title: string;
  bookmarkedDocuments: Object[];
  documentsWithNotes: Object[];
  documentsWithBookmarksOrNotes: Object[];
  searchText: string;
  filteredDocuments: Object[];
}

export default class DocumentListScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      bookmarkedDocuments: [],
      documentsWithNotes: [],
      documentsWithBookmarksOrNotes: [],
      searchText: '',
      filteredDocuments: props.screenProps.screenProps ? props.screenProps.screenProps.documents : props.screenProps.documents,
      notes: [],
    };
  }

  componentDidMount() {
    EventRegister.addEventListener('documents/note-updated', () => {
      // this.willFocusScreen();
      this.updateHeader();
    });

    EventRegister.addEventListener('notes/notes-updated', () => {
      this.willFocusScreen();
      this.updateHeader();
    });

    this.updateHeader();

    this.willFocusScreen().then(() => {
      this.updateHeader();
      this.setupListeners();
    });
  }

  componentWillUnmount() {
    EventRegister.removeEventListener('documents/note-updated');
    EventRegister.removeEventListener('notes/notes-updated');

    if (this.willFocusListener) {
      try {
        this.willFocusListener();
      } catch (error) {
        console.log(error);
      }
    }
  }

  setupListeners() {
    this.willFocusListener = this.props.navigation.addListener('focus', () => {
      this.willFocusScreen();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.screenProps.documents != nextProps.screenProps.documents) {
      setTimeout(() => {
        this.filterResults();
      }, 100);
    }
  }

  updateHeader() {
    this.props.navigation.setOptions({
      title: this.props.route.params ? this.props.route.params.category : 'Documents',
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
        height: Platform.OS === 'web' ? 44 : undefined,
      },
      headerBackTitle: 'Back',
      headerTintColor: '#fff',
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontWeight: '500',
        fontSize: 17,
        marginHorizontal: 0,
        paddingHorizontal: 0
      },
    });
  }

  async willFocusScreen() {
    var bookmarkedDocuments = await DataController.getBookmarkedDocuments(this.props.screenProps.screenProps ? this.props.screenProps.screenProps.documents : this.props.screenProps.documents);
    var documentsWithNotes = await DataController.getDocumentsWithNotes(this.props.screenProps.screenProps ? this.props.screenProps.screenProps.documents : this.props.screenProps.documents);
    var notes = await DataController.getAllNotes();
    var documentsWithBookmarksOrNotes = _.union(bookmarkedDocuments, documentsWithNotes);

    this.setState({ bookmarkedDocuments: bookmarkedDocuments, documentsWithNotes: documentsWithNotes, documentsWithBookmarksOrNotes: documentsWithBookmarksOrNotes, notes }, () => {
      // this.setState({ bookmarkedDocuments: bookmarkedDocuments, documentsWithNotes: documentsWithNotes }, () => {
      this.filterResults();
    });
  }

  renderItem({ item }) {
    var document = item;

    var bookmarkImageSource = null;
    var noteImageSource = null;

    var isBookmarked = this.state.bookmarkedDocuments.includes(document);
    var hasNote = this.state.documentsWithNotes.includes(document);

    if (isBookmarked) {
      bookmarkImageSource = images.Icon_Document_Bookmark;
    }

    if (hasNote) {
      noteImageSource = images.Icon_Document_Note_Active;
    } else {
      noteImageSource = images.Icon_Document_Note_Inactive;
    }

    var gsNumber = 'GS';

    if (document['GS Number'] != null) {
      gsNumber = document['GS Number'];
    }

    var category = '';

    if (document.Category != null) {
      category = document.Category;
    }

    var categoryLayout = (
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginStart: 8, width: 1, height: 16, backgroundColor: '#CFD3D6' }} />
        <Text numberOfLines={1} style={{
          fontFamily: 'Inter-Regular',
          fontSize: 15,
          fontWeight: 'normal',
          fontStyle: 'normal',
          marginStart: 8,
          lineHeight: 20,
          letterSpacing: -0.3,
          color: '#5042A4',
        }}>{category}</Text>
      </View>
    );

    if (this.props.route.params && this.props.route.params.category != null && this.props.route.params.category !== '') {
      categoryLayout = null;
    }

    var notesForDocument;

    if (this.state.notes && this.state.notes.length > 0) {
      var notesForDocument = this.state.notes.find((doc) => doc.documentId === document.id);

      if (notesForDocument) {
        var notesForDocument = notesForDocument.notes.length;
        notesForDocument = `${notesForDocument} Note${notesForDocument !== 1 ? 's' : ''}`
      } else {
        notesForDocument = '0 Notes';
      }
    } else {
      notesForDocument = '0 Notes';
    }

    return (
      <TouchableOpacity key={document.id} onPress={this.goToDocument.bind(this, document)}>
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 6, paddingVertical: 16 }}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View>
              <Image source={noteImageSource} style={{ height: 22, width: 22 }} />
              <Image source={bookmarkImageSource} style={{ height: 22, width: 22 }} />
            </View>
            <View style={{ flexGrow: 1, marginLeft: 6, maxWidth: '90%' }}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                {/* <Image source={bookmarkImageSource} style={{ marginTop: 2 }} /> */}
                <View style={{ flex: 1 }} >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      numberOfLines={1}
                      style={{
                        fontFamily: "Inter-Regular",
                        fontSize: 15,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        lineHeight: 20,
                        letterSpacing: -0.3,
                        color: "#7f8a93",
                        alignSelf: 'center',
                      }}>
                      {gsNumber}
                    </Text>
                    {categoryLayout}
                  </View>
                  <Text
                    style={{
                      marginTop: 6,
                      fontFamily: 'Inter-SemiBold',
                      fontSize: 17,
                      fontWeight: Platform.OS === 'android' ? 'normal' : '600',
                      fontStyle: 'normal',
                      lineHeight: 24,
                      letterSpacing: -0.2,
                      color: '#212529',
                    }}>
                    {document['Document title']}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Inter-Regular",
                      fontSize: 16,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      lineHeight: 24,
                      letterSpacing: -0.2,
                      color: "#5f6e78",
                      marginTop: 8,
                    }}>
                    {`${document.Pages ? document.Pages : 'Unknown'} Page${document.Pages !== 1 ? 's' : ''} • ${notesForDocument}`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ height: 44, width: 44, marginEnd: 4, justifyContent: 'center', alignItems: 'center' }}>
            <Image resizeMode={Platform.OS === 'web' ? 'center' : null} style={styles.icon} source={images.Button_Disclosure_Right} />
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  clearSearch() {
    this.searchTextDidChange("");
  }

  searchTextDidChange(text: string) {

    var that = this

    this.setState({ searchText: text }, function () {
      that.filterResults();
    });
  }

  filterResults() {

    var filteredDocuments = [];

    var searchText = this.state.searchText.toLowerCase();

    // if (this.state.searchText.length > 0) {

    //   for (var documentIndex = 0; documentIndex < this.props.screenProps.documents.length; documentIndex++) {

    //     var document = this.props.screenProps.documents[documentIndex];
    //     var documentTitle = document['Document title'].toLowerCase();
    //     var gsNumber = document['GS Number'];

    //     if (gsNumber != null) {
    //       gsNumber = gsNumber.toLowerCase();
    //     }


    //     if (documentTitle.includes(searchText)) {
    //       filteredDocuments.push(document);
    //     } else if (gsNumber != null && gsNumber.includes(searchText)) {
    //       filteredDocuments.push(document);
    //     }
    //   }
    // } else {
    //   filteredDocuments = this.props.screenProps.documents;
    // }

    if (searchText.length > 0) {
      var options = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        tokenize: true,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "Document title",
          "GS Number"
        ]
      };
      var fuse = new Fuse(this.props.screenProps.screenProps ? this.props.screenProps.screenProps.documents : this.props.screenProps.documents, options); // "list" is the item array
      filteredDocuments = fuse.search(searchText).map((item) => item.item);
    } else {
      filteredDocuments = this.props.screenProps.screenProps ? this.props.screenProps.screenProps.documents : this.props.screenProps.documents;
    }

    var selectedCategory = this.props.route.params ? this.props.route.params.category : null;

    if (selectedCategory != null) {
      filteredDocuments = filteredDocuments && filteredDocuments.filter(function (obj) {
        return obj.Category === selectedCategory;
      });
    }

    this.setState({ filteredDocuments: filteredDocuments });
  }

  renderHeader() {


    return (
      <View>

      </View>
    )
  }

  keyExtractor = (item, index) => item.id;

  async goToDocument(document: Object) {

    var documentDownloaded = await DataController.isDocumentDownloaded(document);
    var isBookmarked = await DataController.isDocumentBookmarked(document);

    if (documentDownloaded) {

      this.props.screenProps.screenProps ? this.props.screenProps.screenProps.openPDF(document, this.props.navigation) : this.props.screenProps.openPDF(document, this.props.navigation);

    } else {
      this.props.screenProps.screenProps ? this.props.screenProps.screenProps.displayNotDownloadedDialog() : this.props.screenProps.displayNotDownloadedDialog();
    }
  }

  render() {

    var clearSearchLayout = null;

    if (this.state.searchText.length > 0) {
      clearSearchLayout = (
        <View>
          <TouchableOpacity onPress={this.clearSearch.bind(this)}>
            <Image style={{ marginRight: 10, tintColor: '#777' }} source={images.close} />
          </TouchableOpacity>
        </View>
      );
    }

    var contentLayout = (
      <ScrollView keyboardShouldPersistTaps='always' stickyHeaderIndices={[0]} bounces={false}>

        <View
          style={{
            backgroundColor: '#8882AB',
            paddingLeft: this.props.screenProps.screenProps ? this.props.screenProps.screenProps.safeAreaInsets.left : this.props.screenProps.safeAreaInsets && this.props.screenProps.safeAreaInsets.left,
            paddingRight: this.props.screenProps.screenProps ? this.props.screenProps.screenProps.safeAreaInsets.right : this.props.screenProps.safeAreaInsets && this.props.screenProps.safeAreaInsets.right
          }}>
          <View style={{ height: 36, flexDirection: 'row', paddingStart: 4, alignItems: 'center', backgroundColor: '#FFFFFF', borderWidth: 1, borderColor: '#777199', borderRadius: 6, marginHorizontal: 16, marginVertical: 6 }}>

            <Image style={{ tintColor: '#97A1A7', height: 30, width: 30 }} source={images.Button_PageSearch_Inactive} />

            <TextInput
              style={{
                flex: 1,
                marginHorizontal: 8,
                fontFamily: 'Inter-Regular',
                fontSize: 17,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: -0.3,
                color: '#212529'
              }}
              underlineColorAndroid="transparent"
              onChangeText={(text) => this.searchTextDidChange(text)}
              value={this.state.searchText}
              placeholder={'Search'}
              placeholderTextColor="#97A1A7"
              autoCapitalize={'none'}
              autoCorrect={false}
              returnKeyType={'search'}
              onSubmitEditing={this.filterResults.bind(this)}
            // onBlur={this.onBlur.bind(this)}
            // onFocus={this.onFocus.bind(this)}
            />
            {clearSearchLayout}

          </View>
        </View>

        <FlatList
          style={{ paddingLeft: this.props.screenProps.screenProps ? this.props.screenProps.screenProps.safeAreaInsets.left : this.props.screenProps.safeAreaInsets.left, paddingRight: this.props.screenProps.screenProps ? this.props.screenProps.screenProps.safeAreaInsets.right : this.props.screenProps.safeAreaInsets.right }}
          data={this.state.filteredDocuments}
          renderItem={this.renderItem.bind(this)}
          keyExtractor={this.keyExtractor}
          keyboardShouldPersistTaps='always'
          ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: '#DDD' }} />}
        />
        {/* <NavigationEvents
          onWillFocus={this.willFocusScreen.bind(this)}
          onDidFocus={payload => console.log('did focus', payload)}
          onWillBlur={payload => console.log('will blur', payload)}
          onDidBlur={payload => console.log('did blur', payload)}
        /> */}
      </ScrollView>
    )

    var contentContainerLayout = (
      <View style={{ flex: 1 }}>
        {contentLayout}
      </View>
    )

    if (Platform.OS === 'ios') {
      contentContainerLayout = (
        <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={64} behavior="padding">
          {contentLayout}
        </KeyboardAvoidingView>
      )
    }

    return (
      <View style={{ flex: 1, backgroundColor: '#FFFFFF', }}>
        {contentContainerLayout}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  icon: Platform.select({
    web: {
      height: Platform.OS === 'web' ? 44 : undefined,
      width: Platform.OS === 'web' ? 44 : undefined,
      alignSelf: 'center',
    },
    default: {
      alignSelf: 'center',
    }
  }),
});