/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component, useMemo } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Dimensions,
  Button
} from 'react-native';
import { TabView, TabBar, SceneMap, PagerScroll } from 'react-native-tab-view';

import TimeTableScreen from '../screens/TimeTableScreen';

import moment from 'moment';
import images from '../resources/images';

type Props = {
  synod: ?Object,
  timeTableItems: Object[],
  documents: Object[]
}

type State = {
  index: number,
  synod: ?Object,
  timeTableItems: Object[],
  documents: Object[],
  recentlyDownloadedDocuments: Object[],
  tabViewKey: number,
}

export default class TimeTableContainerScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      index: 0,
      routes: [
        { key: 'first', title: 'WED' },
        { key: 'second', title: 'THU' },
        { key: 'third', title: 'FRI' },
        { key: 'fourth', title: 'SAT' },
        // { key: 'fifth', title: 'TUE' },
      ],
      synod: null,
      timeTableItems: [],
      documents: [],
      recentlyDownloadedDocuments: [],
      successfullyDownloadedDocuments: true,
      timeTableHasBeenUpdatedSinceLastDisplay: false,
      tabViewKey: 1,
    };
  }

  UNSAFE_componentWillMount() {
    // set header button functions (crashes if not on a delay)
    var that = this;
    setTimeout(() => {
      that.props.navigation.setParams({ recentlyDownloadedDocuments: that.state.recentlyDownloadedDocuments, successfullyDownloadedDocuments: that.state.successfullyDownloadedDocuments, timeTableHasBeenUpdatedSinceLastDisplay: that.state.timeTableHasBeenUpdatedSinceLastDisplay, accountInfo: that.accountInfo.bind(that), displayUpdatesDialog: that.displayUpdatesDialog.bind(that), goToInfo: that.goToInfo.bind(that), synod: that.state.synod });
    }, 100);

  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {

    // console.log("TimeTableContainerScreen: UNSAFE_componentWillReceiveProps")

    var tabViewKey = this.state.tabViewKey;

    if (nextProps.screenProps.screenProps.synod !== this.state.synod || nextProps.screenProps.screenProps.timeTableItems !== this.state.timeTableItems || nextProps.screenProps.screenProps.documents !== this.state.documents || nextProps.screenProps.screenProps.recentlyDownloadedDocuments !== this.state.recentlyDownloadedDocuments) {

      if (Platform.OS === 'android') {
        tabViewKey = tabViewKey + 1;
      }
    }

    var shouldOpenToCurrentDay = (this.state.synod !== nextProps.screenProps.screenProps.synod);

    this.setState({ synod: nextProps.screenProps.screenProps.synod, timeTableItems: nextProps.screenProps.screenProps.timeTableItems, documents: nextProps.screenProps.screenProps.documents, recentlyDownloadedDocuments: nextProps.screenProps.screenProps.recentlyDownloadedDocuments, successfullyDownloadedDocuments: nextProps.screenProps.screenProps.successfullyDownloadedDocuments, timeTableHasBeenUpdatedSinceLastDisplay: nextProps.screenProps.screenProps.timeTableHasBeenUpdatedSinceLastDisplay, tabViewKey: tabViewKey }, function () {
      this.updateData();

      // if ()

      if (shouldOpenToCurrentDay) {
        this.openToCurrentDay();
      }
    });
  }

  componentDidMount() {
    this.updateHeader();
  }

  updateHeader() {
    var recentlyDownloadedDocuments = this.props.route.params && this.props.route.params.recentlyDownloadedDocuments;
    var successfullyDownloadedDocuments = this.props.route.params && this.props.route.params.successfullyDownloadedDocuments;
    var timeTableHasBeenUpdatedSinceLastDisplay = this.props.route.params && this.props.route.params.timeTableHasBeenUpdatedSinceLastDisplay;

    var updateIconSource = images.Button_Updates_Base;

    if (recentlyDownloadedDocuments && recentlyDownloadedDocuments.length > 0 || successfullyDownloadedDocuments == false || timeTableHasBeenUpdatedSinceLastDisplay) {
      updateIconSource = images.Button_Updates_Active;
    }

    var updateButtonLayout = () => (
      <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => this.props.route.params && this.props.route.params.accountInfo()}>
        <Image resizeMode={Platform.OS === 'web' ? 'center' : null} source={images.Button_Account} style={styles.icon} />
      </TouchableOpacity>
    )

    var synod = this.props.route.params && this.props.route.params.synod;

    var title = 'General Synod';

    this.props.navigation.setOptions({
      title: title,
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
        height: Platform.OS === 'web' ? 44 : undefined,
      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontFamily: 'Inter-SemiBold',
        fontSize: 17,
        fontWeight: Platform.OS === 'android' ? 'normal' : '600',
        letterSpacing: -0.2,
        color: '#F1F3F5',
        marginHorizontal: 0,
        paddingHorizontal: 0,
      },
      headerTitleAlign: 'center',
      headerLeft: updateButtonLayout,
      headerRight: () => (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => this.props.route.params && this.props.route.params.displayUpdatesDialog()}>
            <Image resizeMode={Platform.OS === 'web' ? 'center' : null} source={updateIconSource} style={styles.icon} />
          </TouchableOpacity>
          <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => this.props.route.params && this.props.route.params.goToInfo()}>
            <Image resizeMode={Platform.OS === 'web' ? 'center' : null} source={images.Button_Info} style={styles.icon} />
          </TouchableOpacity>
        </View>
      ),
    });
  }

  updateData() {
    if (this.state.synod != null) {
      // __DEV__ && console.log(this.state.synod);
      // decide what day tabs to show
      let synodStartDate = new Date(this.state.synod.StartDate);
      let synodEndDate = new Date(this.state.synod.EndDate);
      var synodDates = this.getDates(synodStartDate, synodEndDate);

      var routes = [];

      for (var dateIndex = 0; dateIndex < synodDates.length; dateIndex++) {

        var synodDate = synodDates[dateIndex];

        var dateString = moment(synodDate).format('YYYY-MM-DD').toUpperCase();
        var weekDayName = moment(synodDate).format('ddd').toUpperCase();

        var timeTableItemsForDay = [];

        for (var timeTableItemIndex = 0; timeTableItemIndex < this.state.timeTableItems.length; timeTableItemIndex++) {

          var timeTableItem = this.state.timeTableItems[timeTableItemIndex];

          if (timeTableItem.Date === dateString) {
            timeTableItemsForDay.push(timeTableItem);
          }
        }

        routes.push({ key: dateString, title: weekDayName, timeTableItems: timeTableItemsForDay })
      }

      if (this.state.index >= routes.length) {
        this.setState({ index: 0 });
      }

      this.setState({ routes: routes });

      if (this.props.route.params && (this.props.route.params.recentlyDownloadedDocuments != this.state.recentlyDownloadedDocuments)) {
        this.props.navigation.setParams({ recentlyDownloadedDocuments: this.state.recentlyDownloadedDocuments });
      }

      if (this.props.route.params && (this.props.route.params.successfullyDownloadedDocuments != this.state.successfullyDownloadedDocuments)) {
        this.props.navigation.setParams({ successfullyDownloadedDocuments: this.state.successfullyDownloadedDocuments });
      }

      if (this.props.route.params && (this.props.route.params.timeTableHasBeenUpdatedSinceLastDisplay != this.state.timeTableHasBeenUpdatedSinceLastDisplay)) {
        this.props.navigation.setParams({ timeTableHasBeenUpdatedSinceLastDisplay: this.state.timeTableHasBeenUpdatedSinceLastDisplay });
      }
    }
  }

  openToCurrentDay() {

    var today = moment().startOf('day');

    var firstDayOfSynod = moment(this.state.synod.StartDate);
    var lastDayOfSynod = moment(this.state.synod.EndDate);
    var todayToFirstDayDifference = today.diff(firstDayOfSynod, 'days')
    var firstDayToLastDayDifference = lastDayOfSynod.diff(firstDayOfSynod, 'days')

    if (todayToFirstDayDifference < 0) {
      this.setState({ index: 0 });
    } else if (todayToFirstDayDifference >= 0 && todayToFirstDayDifference <= firstDayToLastDayDifference) {
      this.setState({ index: todayToFirstDayDifference });
    } else {
      this.setState({ index: firstDayToLastDayDifference });
    }
  }

  displayUpdatesDialog() {
    console.log('display updates');

    if (Platform.OS === 'web') {
      this.props.screenProps.screenProps.displayUpdateModalWeb();
    } else {
      this.props.screenProps.screenProps.displayUpdatesDialog();
    }
  }

  goToInfo() {
    this.props.navigation.navigate('Info');
  }

  accountInfo() {
    this.props.screenProps.screenProps.accountInfo();
  }

  render() {

    var renderScene = ({ route }) => {

      for (var routeIndex = 0; routeIndex < this.state.routes.length; routeIndex++) {

        var aRoute = this.state.routes[routeIndex];

        if (aRoute.key === route.key) {

          if (route.timeTableItems != null) {
            return <TimeTableScreen navigation={this.props.navigation} screenProps={this.props.screenProps.screenProps} day={route.key} timeTableItems={route.timeTableItems} documents={this.props.screenProps.screenProps.documents} />
          } else {
            return <View />
          }
        }
      }
    }

    return (

      <View style={{ flex: 1 }}>
        <TabView
          key={this.state.tabViewKey}
          navigationState={this.state}
          // renderScene={SceneMap(daySceneMap)}
          renderScene={renderScene}
          onIndexChange={index => this.setState({ index })}
          initialLayout={{ width: Dimensions.get('window').width, height: 400 }}
          renderTabBar={props =>
            <TabBar
              {...props}
              style={{ height: 40, backgroundColor: '#8882AB' }}
              indicatorStyle={{ backgroundColor: '#F3B473', height: 4 }}
              renderLabel={({ route, focused, color }) =>
                <Text
                  style={{
                    fontFamily: 'Inter-Bold',
                    fontWeight: Platform.OS === 'android' ? 'normal' : 'bold',
                    fontStyle: 'normal',
                    letterSpacing: -0.2,
                    color: '#F1F3F5',
                    opacity: focused ? 1 : 0.6,
                    fontSize: 16,
                    marginBottom: 7,
                  }}>
                  {route.title}
                </Text>
              }
            />
          }
        />
      </View>
    )
  }

  addDaysToDate(currentDate, days) {
    var date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = this.addDaysToDate(currentDate, 1);
    }
    return dateArray;
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  icon: Platform.select({
    web: {
      height: Platform.OS === 'web' ? 44 : undefined,
      width: Platform.OS === 'web' ? 44 : undefined,
      alignSelf: 'center',
    },
    default: {
      alignSelf: 'center',
    }
  }),
});