/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Dimensions,
  ScrollView,
  FlatList,
  Alert,
} from 'react-native';
import DataController from '../controllers/DataController';
import Pdf from 'react-native-pdf';
import Share from 'react-native-share';
import Popover from 'react-native-popover-view'
import images from '../resources/images';

type Props = {

}

type State = {

}

export default class DocumentShareMenu extends Component<Props, State> {

  constructor(props: Props) {

    super(props);

    this.state = {

    };
  }

  async componentDidMount() {

  }

  switchMode() {
    this.props.closePopover();

    setTimeout(() => {
      this.props.togglePaging();
    }, 50);
  }

  share() {
    if (Platform.OS === 'web') {
      this.props.downloadDocument();
      return;
    }

    var document = this.props.document;

    var pdfFilePath = 'file://' + DataController.getFilePathForDocument(document);

    const shareOptions = {
      title: document['Document title'],
      message: document['Document title'],
      url: pdfFilePath,
    };

    Share.open(shareOptions)
      .then((res) => {
        this.props.closePopover();
      })
      .catch((err) => {
        err && console.log(err);
        this.props.closePopover();
      });
  }

  render() {

    var pagingIconSource = images.Button_Paging;
    var pagingText = 'Switch to paging';
    if (this.props.pagingEnabled) {
      pagingIconSource = images.Button_Scrolling;
      pagingText = 'Switch to scrolling';
    }

    var shareIconSource = images.Button_Share_iOS;

    if (Platform.OS === 'android') {
      shareIconSource = images.Button_Share_Android;
    }

    return (
      <View style={{ flex: 1, width: 200, backgroundColor: '#FFFFFF' }}>

        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', height: 50 }} onPress={this.switchMode.bind(this)}>
          <Text
            allowFontScaling={false}
            style={{
              fontFamily: 'Inter-Regular',
              fontSize: 17,
              fontWeight: 'normal',
              letterSpacing: -0.2,
              color: '#000000'
            }}>
            {pagingText}
          </Text>
          <View style={{ height: 44, width: 44, justifyContent: 'center', alignItems: 'center' }}>
            <Image source={pagingIconSource} resizeMode="contain" style={{ tintColor: '#000', height: 26, width: 19 }} />
          </View>
        </TouchableOpacity>

        <View style={{ height: 1, backgroundColor: '#CFD3D6' }} />

        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', height: 50 }} onPress={this.share.bind(this)}>
          <Text
            allowFontScaling={false}
            style={{
              fontFamily: 'Inter-Regular',
              fontSize: 17,
              fontWeight: 'normal',
              letterSpacing: -0.2,
              color: '#000000'
            }}>
            {Platform.OS === 'web' ? 'Download' : 'Share'}
          </Text>
          <View style={{ height: 44, width: 44, justifyContent: 'center', alignItems: 'center' }}>
            <Image source={shareIconSource} resizeMode="contain" style={{ tintColor: '#000', height: 26, width: 19 }} />
          </View>
        </TouchableOpacity>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
});