/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Dimensions,
  ScrollView,
  FlatList,
  TextInput,
  KeyboardAvoidingView,
} from 'react-native';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

// import { NavigationEvents } from 'react-navigation';

import Fuse from 'fuse.js';

import DataController from '../controllers/DataController';
import images from '../resources/images';

type Props = {
  title: string;
  documents: Object[];
}

type State = {
  title: string;
  searchText: string;
  documents: Object[];
  categories: Object[];
  filteredDocuments: Object[];
  filteredCategories: Object[];
  bookmarkedDocuments: Object[];
  documentsWithNotes: Object[];
}

export default class DocumentCategoriesScreen extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      title: '',
      searchText: '',
      documents: this.props.screenProps.documents,
      categories: [],
      filteredDocuments: [],
      filteredCategories: [],
      bookmarkedDocuments: [],
      documentsWithNotes: [],
      notes: [],
    };
  }

  componentDidMount() {
    this.updateSections();
  }

  async willFocusScreen() {

    var bookmarkedDocuments = await DataController.getBookmarkedDocuments(this.props.screenProps.documents);
    var documentsWithNotes = await DataController.getDocumentsWithNotes(this.props.screenProps.documents);
    var notes = await DataController.getAllNotes();

    this.setState({ bookmarkedDocuments: bookmarkedDocuments, documentsWithNotes: documentsWithNotes, notes });

    this.updateSections();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.screenProps.documents != nextProps.screenProps.documents) {
      setTimeout(() => {
        this.updateSections();
      }, 100);
    }
  }

  updateSections() {

    var categories = [];

    for (var documentIndex = 0; documentIndex < this.props.screenProps.documents.length; documentIndex++) {

      var document = this.props.screenProps.documents[documentIndex];

      if (document.Category != null && document.Category !== '' && categories.includes(document.Category) == false) {
        categories.push(document.Category);
      }
    }

    categories.sort();

    this.setState({ categories: categories, filteredCategories: categories, filteredDocuments: this.props.screenProps.documents })
  }

  async goToDocument(document: Object) {

    var documentDownloaded = await DataController.isDocumentDownloaded(document);
    var isBookmarked = await DataController.isDocumentBookmarked(document);

    if (documentDownloaded) {

      this.props.screenProps.openPDF(document, this.props.navigation);

    } else {
      this.props.screenProps.displayNotDownloadedDialog();
    }
  }

  renderItem({ item }) {

    if (typeof item === 'string') {
      // this is a string

      var category = item;

      return (
        <TouchableOpacity key={category} onPress={this.goToCategory.bind(this, category)}>
          <View style={{ height: 70, flexDirection: 'row', alignItems: 'center', paddingStart: 14, paddingEnd: 0 }}>
            <Text style={{
              flex: 1, marginTop: 6,
              fontFamily: 'Inter-SemiBold',
              fontWeight: Platform.OS === 'android' ? 'normal' : '600',
              fontSize: 17,
              fontStyle: "normal",
              lineHeight: 24,
              letterSpacing: -0.2,
              color: '#212529',
            }}>
              {category}
            </Text>
            <View style={{ height: 44, width: 44, marginEnd: 4, justifyContent: 'center', alignItems: 'center' }}>
              <Image resizeMode={Platform.OS === 'web' ? 'center' : null} style={styles.icon} source={images.Button_Disclosure_Right} />
            </View>
          </View>
        </TouchableOpacity>
      )

    } else {

      var document = item;

      var bookmarkImageSource = null;
      var noteImageSource = null;

      var isBookmarked = this.state.bookmarkedDocuments.includes(document);
      var hasNote = this.state.documentsWithNotes.includes(document);

      if (isBookmarked) {
        bookmarkImageSource = images.Icon_Document_Bookmark;
      }

      if (hasNote) {
        noteImageSource = images.Icon_Document_Note_Active;
      } else {
        noteImageSource = images.Icon_Document_Note_Inactive;
      }

      var gsNumber = 'GS';
      var category = '';

      if (document['GS Number'] != null) {
        gsNumber = document['GS Number'];
      }

      if (document.Category != null) {
        category = document.Category;
      }

      var notesForDocument;

      if (this.state.notes && this.state.notes.length > 0) {
        var notesForDocument = this.state.notes.find((doc) => doc.documentId === document.id);

        if (notesForDocument) {
          var notesForDocument = notesForDocument.notes.length;
          notesForDocument = `${notesForDocument} Note${notesForDocument !== 1 ? 's' : ''}`
        } else {
          notesForDocument = '0 Notes';
        }
      } else {
        notesForDocument = '0 Notes';
      }

      return (
        <TouchableOpacity key={document.id} onPress={this.goToDocument.bind(this, document)}>
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 6, paddingVertical: 16 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View>
                <Image source={noteImageSource} style={{ height: 22, width: 22 }} />
                <Image source={bookmarkImageSource} style={{ height: 22, width: 22 }} />
              </View>
              <View style={{ flexGrow: 1, marginLeft: 6, maxWidth: '90%' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text
                    numberOfLines={1}
                    style={{
                      fontFamily: "Inter-Regular",
                      fontSize: 15,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      lineHeight: 20,
                      letterSpacing: -0.3,
                      color: "#7f8a93"
                    }}>
                    {gsNumber}
                  </Text>
                  <View style={{ marginStart: 8, width: 1, height: 16, backgroundColor: '#CFD3D6' }} />
                  <Text
                    numberOfLines={1}
                    style={{
                      flex: -1,
                      fontFamily: "Inter-Regular",
                      fontSize: 15,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      lineHeight: 20,
                      letterSpacing: -0.3,
                      color: "#5042a4",
                      marginStart: 8,
                    }}>
                    {category}
                  </Text>
                </View>
                <Text
                  style={{
                    marginTop: 6,
                    fontFamily: 'Inter-SemiBold',
                    fontSize: 17,
                    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
                    fontStyle: 'normal',
                    lineHeight: 24,
                    letterSpacing: -0.2,
                    color: '#212529',
                  }}>
                  {document['Document title']}
                </Text>
                <Text
                  style={{
                    fontFamily: "Inter-Regular",
                    fontSize: 16,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    lineHeight: 24,
                    letterSpacing: -0.2,
                    color: "#5f6e78",
                    marginTop: 8,
                  }}>
                  {`${document.Pages ? document.Pages : 'Unknown'} Page${document.Pages !== 1 ? 's' : ''} • ${notesForDocument}`}
                </Text>
              </View>
            </View>
            <View style={{ height: 44, width: 44, marginEnd: 4, justifyContent: 'center', alignItems: 'center' }}>
              <Image resizeMode={Platform.OS === 'web' ? 'center' : null} style={styles.icon} source={images.Button_Disclosure_Right} />
            </View>
          </View>
        </TouchableOpacity>
      )
    }
  }

  clearSearch() {
    this.searchTextDidChange("");
  }

  searchTextDidChange(text: string) {

    var that = this

    this.setState({ searchText: text }, function () {
      that.filterResults();
    });
  }

  filterResults() {

    var filteredDocuments = [];

    var searchText = this.state.searchText.toLowerCase();

    if (searchText.length > 0) {
      var options = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        tokenize: true,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "Document title",
          "GS Number"
        ]
      };
      var fuse = new Fuse(this.props.screenProps.documents, options); // "list" is the item array
      filteredDocuments = fuse.search(searchText).map((item) => item.item);
    } else {
      filteredDocuments = this.props.screenProps.documents;
    }

    var selectedCategory = this.props.route.params ? this.props.route.params.category : null;
    // this.props.navigation.getParam('category', null);

    if (selectedCategory != null) {
      filteredDocuments = filteredDocuments.filter(function (obj) {
        return obj.Category === selectedCategory;
      });
    }

    this.setState({ filteredDocuments: filteredDocuments });
  }

  renderHeader() {


    return (
      <View>

      </View>
    )
  }

  keyExtractor(item, index) {

    if (typeof item === 'string') {
      return item;
    } else {
      return item.id;
    }
  }

  async goToCategory(category: string) {

    this.props.navigation.navigate('DocumentList', {
      documents: this.props.screenProps.documents,
      category: category
    });
  }

  render() {

    var items = this.state.filteredCategories;

    if (this.state.searchText.length > 0) {
      items = this.state.filteredDocuments;
    }

    var clearSearchLayout = null;

    if (this.state.searchText.length > 0) {
      clearSearchLayout = (
        <View>
          <TouchableOpacity onPress={this.clearSearch.bind(this)}>
            <Image style={{ marginRight: 10, tintColor: '#777' }} source={images.close} />
          </TouchableOpacity>
        </View>
      );
    }

    var contentLayout = (
      <ScrollView keyboardShouldPersistTaps='always' stickyHeaderIndices={[0]} bounces={false}>

        <View style={{ backgroundColor: '#8882AB', paddingLeft: this.props.screenProps.safeAreaInsets && this.props.screenProps.safeAreaInsets.left, paddingRight: this.props.screenProps.safeAreaInsets && this.props.screenProps.safeAreaInsets.right }}>
          <View style={{ height: 36, flexDirection: 'row', paddingStart: 4, alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#777199', borderRadius: 6, marginHorizontal: 16, marginVertical: 6 }}>

            <Image style={{ tintColor: '#97A1A7', height: 30, width: 30 }} source={images.Button_PageSearch_Inactive} />

            <TextInput
              style={{
                flex: 1,
                marginHorizontal: 8,
                fontFamily: 'Inter-Regular',
                fontSize: 17,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: -0.3,
                color: '#212529'
              }}
              underlineColorAndroid="transparent"
              onChangeText={(text) => this.searchTextDidChange(text)}
              value={this.state.searchText}
              placeholder={'Search'}
              placeholderTextColor="#97A1A7"
              autoCapitalize={'none'}
              autoCorrect={false}
              returnKeyType={'search'}
              onSubmitEditing={this.filterResults.bind(this)}
            // onBlur={this.onBlur.bind(this)}
            // onFocus={this.onFocus.bind(this)}
            />
            {clearSearchLayout}

          </View>
        </View>

        <FlatList
          style={{ paddingLeft: this.props.screenProps.safeAreaInsets.left, paddingRight: this.props.screenProps.safeAreaInsets.right }}
          data={items}
          renderItem={this.renderItem.bind(this)}
          keyExtractor={this.keyExtractor}
          ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: '#DDD' }} />}
        />
        {/* <NavigationEvents
          onWillFocus={this.willFocusScreen.bind(this)}
          onDidFocus={payload => console.log('did focus',payload)}
          onWillBlur={payload => console.log('will blur',payload)}
          onDidBlur={payload => console.log('did blur',payload)}
        /> */}
      </ScrollView>
    )

    var contentContainerLayout = (
      <View style={{ flex: 1, backgroundColor: '#FFFFFF', }}>
        {contentLayout}
      </View>
    )

    if (Platform.OS === 'ios') {
      contentContainerLayout = (
        <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={64} behavior="padding">
          {contentLayout}
        </KeyboardAvoidingView>
      )
    }

    return (
      <View style={{ flex: 1 }}>
        {contentContainerLayout}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  icon: Platform.select({
    web: {
      height: Platform.OS === 'web' ? 44 : undefined,
      width: Platform.OS === 'web' ? 44 : undefined,
      alignSelf: 'center',
    },
    default: {
      alignSelf: 'center',
    }
  }),
});