/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
  Dimensions,
  SafeAreaView,
} from 'react-native';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

import DocumentListScreen from '../screens/DocumentListScreen';
import DocumentCategoriesScreen from '../screens/DocumentCategoriesScreen';

import moment from 'moment';

type Props = {
  synod: ?Object,
  timeTableItems: Object[],
  documents: Object[]
}

type State = {
  synod: ?Object,
  timeTableItems: Object[],
  documents: Object[]
}

export default class DocumentsContainerScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      index: 0,
      routes: [
        { key: 'Categories', title: 'Categories' },
        { key: 'All Documents', title: 'All Documents' },
      ],
      synod: null,
      timeTableItems: [],
      documents: []
    };
  }

  componentDidMount() {
    this.updateHeader();
  }

  updateHeader() {
    this.props.navigation.setOptions({
      headerShown: false,
      headerStyle: {
        borderBottomWidth: 0,
      }
    });
  }

  render() {

    var renderScene = ({ route }) => {

      if (route.key === "Categories") {
        return <DocumentCategoriesScreen navigation={this.props.navigation} route={this.props.route} screenProps={this.props.screenProps.screenProps} />
      } else {
        return <DocumentListScreen navigation={this.props.navigation} route={this.props.route} screenProps={this.props.screenProps.screenProps} />
      }
    }

    var topPaddingView = null;

    if (Platform.OS === 'ios') {
      const majorVersionIOS = parseInt(Platform.Version, 10);

      if (majorVersionIOS <= 10) {
        topPaddingView = (
          <View style={{ height: 20, backgroundColor: '#382E73' }} />
        );
      }
    }

    return (
      <View style={{ flex: 1, backgroundColor: '#382E73', paddingTop: this.props.screenProps.screenProps.safeAreaInsets.top }}>
        {topPaddingView}
        <View style={{ flex: 1, backgroundColor: '#FFF' }}>
          {/* <View style={{height: 20, backgroundColor: '#382E73'}}/> */}
          <TabView
            navigationState={this.state}
            // renderScene={SceneMap(daySceneMap)}
            renderScene={renderScene}
            onIndexChange={index => this.setState({ index })}
            initialLayout={{ width: Dimensions.get('window').width, height: 100 }}
            renderTabBar={props =>
              <TabBar
                {...props}
                style={{ height: 44, backgroundColor: '#382E73' }}
                indicatorStyle={{ backgroundColor: '#F3B473', height: 4 }}
                renderLabel={({ route, focused, color }) =>
                  <Text
                    style={{
                      fontFamily: 'Inter-SemiBold',
                      fontWeight: Platform.OS === 'android' ? 'normal' : '600',
                      fontStyle: 'normal',
                      letterSpacing: -0.2,
                      color: '#F1F3F5',
                      opacity: focused ? 1 : 0.6,
                      fontSize: 16,
                      marginBottom: 7,
                    }}>
                    {route.title}
                  </Text>
                }
              />
            }
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
});