/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Platform,
  StyleSheet,
  Dimensions,
  Button,
  ScrollView,
  Alert,
  Linking,
} from 'react-native';
import { WebView } from 'react-native-webview';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { isTablet } from 'react-native-device-info';
import TestModeModal from '../components/TestModeModal';

type Props = {

}

type State = {
  testVersionCounter: boolean,
  alertVisible: Boolean,
  mode: String,
}

export default class InfoScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      testVersionCounter: 0,
      alertVisible: false,
      mode: '',
    };
  }

  componentDidMount() {
    this.props.navigation.setParams({ toggleTestMode: this.toggleTestMode.bind(this) });
    this.updateHeader();
  }

  updateHeader() {
    this.props.navigation.setOptions({
      title: 'Information',
      headerBackTitle: 'Back',
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
        height: Platform.OS === 'web' ? 44 : undefined,
      },
      headerTintColor: '#fff',
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontFamily: 'Inter-SemiBold',
        fontSize: 17,
        fontWeight: Platform.OS === 'android' ? 'normal' : '600',
        letterSpacing: -0.2,
        color: '#F1F3F5',
        marginHorizontal: 0,
        paddingHorizontal: 0,
      },
      headerRight: () => (
        <TouchableOpacity onPress={() => this.props.route.params && this.props.route.params.toggleTestMode()}>
          <View style={{ width: 44, height: 44 }} />
        </TouchableOpacity>
      ),
    });
  }

  async toggleTestMode() {

    if (this.state.testVersionCounter >= 4) {

      var testModeEnabled = await AsyncStorage.getItem("testModeEnabled");
      var modeText = 'test';

      if (testModeEnabled != null) {
        // test mode currently on - turn off test mode
        AsyncStorage.removeItem('testModeEnabled')
        modeText = 'live';
      } else {
        // test mode current off - turn test mode on
        AsyncStorage.setItem("testModeEnabled", "true")
      }

      if (Platform.OS === 'web') {
        this.setState({
          alertVisible: true,
          mode: modeText,
        });
      } else {
        Alert.alert(
          'Now using ' + modeText + ' version',
          "Please close and reopen the app",
          [
            { text: 'OK', onPress: () => console.log('toggledTesting') },
          ],
          { cancelable: false }
        );
      }

      this.setState({ testVersionCounter: 0 });

    } else {
      this.setState({ testVersionCounter: this.state.testVersionCounter + 1 });
    }
  }

  handleURL(url: string) {

    Linking.openURL(url).catch(err => console.error('An error occurred', err));
  }

  render() {

    var shouldScalePageToFit = false;

    if (Platform.OS === 'android') {
      shouldScalePageToFit = true;
    }

    var html = `<!DOCTYPE html>
      <html>
        <head>
        <style>
    
        body {
          background-color: #F3F2F7;
          margin: ${isTablet() ? 14 : 38}px;
          font-size: ${isTablet() ? 18 : 42}px;
          line-height: ${isTablet() ? 25 : 48}x;
          color: #374047;
          font-family: 'Inter-Regular', Helvetica, Arial, sans-serif;
        }

        </style>
    
        </head>
        <body>
        The General Synod app is designed for use by members of the General Synod of the Church of England. It allows members to access a live timetable of events, all the documents relating to the current group of sessions (downloaded to the device in use) and a 'My Synod' section where documents can be marked as favourites for quick reference. Document notes and settings are synchronised across your different devices.
        <br/><br/>
        If you have any queries relating to General Synod, please use the 'Contact' tab in the bottom right of the screen to send a message to the Synod team.
        <br/><br/>
        Copyright © 2021 Archbishops' Council. All rights reserved.
        </body>
      </html>`;

    if (Platform.OS === 'web') {
      return (
        <ScrollView
          style={{ flex: 1, backgroundColor: '#F3F2F7' }}
          contentContainerStyle={{ padding: 16 }}>
          <TestModeModal
            visible={this.state.alertVisible}
            mode={this.state.mode}
            close={() => this.setState({ alertVisible: false })}
          />
          <Text style={styles.text}>
            The General Synod app is designed for use by members of the General Synod of the Church of England. It allows members to access a live timetable of events, all the documents relating to the current group of sessions (downloaded to the device in use) and a 'My Synod' section where documents can be marked as favourites for quick reference. Document notes and settings are synchronised across your different devices.
          </Text>
          <Text style={[styles.text, styles.textMargin]}>
            If you have any queries relating to General Synod, please use the 'Contact' tab in the bottom right of the screen to send a message to the Synod team.
          </Text>
          <Text style={[styles.text, styles.textMargin]}>
            Copyright © 2021 Archbishops' Council. All rights reserved.
          </Text>
        </ScrollView>
      )
    }

    return (
      <View style={{ flex: 1, backgroundColor: '#F3F2F7' }}>
        <WebView
          source={{ html: html }}
          dataDetectorTypes={'none'}
          originWhitelist={["file://"]}
          allowFileAccess={true}
          startInLoadingState={true}
          onShouldStartLoadWithRequest={(event) => {
            __DEV__ && console.log(event.url);
            if (event.url.startsWith('http') || event.url.startsWith('www') || event.url.startsWith('mailto')) {
              this.handleURL(event.url);
              return false;
            }
            return true;
          }}
          scalesPageToFit={shouldScalePageToFit}
          containerStyle={{
            paddingLeft: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.left,
            paddingRight: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.right,
          }}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  text: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#374047',
  },
  textMargin: {
    marginTop: 20,
  },
});