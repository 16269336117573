/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Platform,
  StyleSheet,
  Dimensions,
  Button,
  ScrollView,
  Alert,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  InputAccessoryView,
  Linking,
  ActivityIndicator,
  BackHandler,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNPickerSelect from 'react-native-picker-select';
import CheckBoxGroup from '../components/CheckBoxGroup';
import images from '../resources/images';
import FirebaseService from '../controllers/FirebaseService';
import validator from 'validator';

type Props = {
  synod: ?Object,
  timeTableItems: Object[],
  documents: Object[]
}

type State = {
  synod: ?Object,
  timeTableItems: Object[],
  documents: Object[],
  diocese: Object[],
  selectedDiocese: ?Object,
  pmmChoices: Object[],
  selectedPmmChoice: ?Object,
  nameText: string,
  emailText: string,
  queryText: string,
  items: Object[],
  selectedItem: ?Object,
  otherItemNumberText: string,
  synodNumberText: string,
  requestSummaryText: string,
  requestReasonText: string,
  speakOnAmendmentOption: string,
  amendmentText: string,
  addressingRemotelyOption: string,
  forOrAgainstAmendmentOption: string,
  maidenSpeechOption: string,
  forOrAgainstMotionOption: string,
  requireMicrophoneOption: string,
  firstNameText: string,
  surnameText: string,
  postcodeText: string,
  waitingForSendResponse: boolean,
}

export default class ContactScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      synod: null,
      timeTableItems: [],
      documents: [],
      diocese: [],
      selectedDiocese: null,
      pmmChoices: [],
      selectedPmmChoice: null,
      nameText: '',
      emailText: '',
      queryText: '',
      items: [],
      selectedItem: null,
      otherItemNumberText: '',
      synodNumberText: '',
      requestSummaryText: '',
      requestReasonText: '',
      speakOnAmendmentOption: null,
      amendmentText: '',
      addressingRemotelyOption: null,
      forOrAgainstAmendmentOption: null,
      maidenSpeechOption: null,
      forOrAgainstMotionOption: null,
      requireMicrophoneOption: null,
      firstNameText: '',
      surnameText: '',
      postcodeText: '',
      waitingForSendResponse: false,
    };
  }

  async componentDidMount() {
    var that = this;
    setTimeout(() => {
      that.props.navigation.setParams({ goToInfo: that.goToInfo.bind(that) });
    }, 100);

    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      if (this.props.screenProps.screenProps.shouldShowSubjectPicker) {
        this.props.screenProps.screenProps.showSubjectPicker(false, this.props.route.params.form);
        Keyboard.dismiss();
        return true;
      } else {
        return false;
      }
    });

    this.updateHeader();

    // get default form values
    try {
      var nameText = await AsyncStorage.getItem('FullName');
      var emailText = await AsyncStorage.getItem('Email');
      var selectedDiocese = await AsyncStorage.getItem('Diocese');
      var synodNumberText = await AsyncStorage.getItem('SynodNumber');

      if (nameText != null) {
        this.setState({ nameText: nameText });
      }

      if (emailText != null) {
        this.setState({ emailText: emailText });
      }

      if (selectedDiocese != null) {
        this.setState({ selectedDiocese: selectedDiocese });
      }

      if (synodNumberText != null) {
        this.setState({ synodNumberText: synodNumberText });
      }

    } catch (error) {
      console.log('error getting default form values: ', error);
    }
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {

    this.setState({ synod: nextProps.screenProps.screenProps.synod, timeTableItems: nextProps.screenProps.screenProps.timeTableItems, documents: nextProps.screenProps.screenProps.documents }, function () {
      this.updateData();
    });
  }

  updateHeader() {
    this.props.navigation.setOptions({
      title: this.props.route.params ? this.props.route.params.form : '',
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
        height: Platform.OS === 'web' ? 44 : undefined,
      },
      headerBackTitle: 'Back',
      headerTintColor: '#fff',
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontWeight: '500',
        fontSize: 17,
        marginHorizontal: 4,
        paddingHorizontal: 0,
      },
      headerRight: () => (
        <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => this.props.route.params && this.props.route.params.goToInfo()}>
          <Image resizeMode={Platform.OS === 'web' ? 'center' : null} source={images.Button_Info} style={styles.icon} />
        </TouchableOpacity>
      ),
    });
  }

  updateData() {
    console.log('updateData');

    var dioceseArray = [];

    for (let dioceseIndex = 0; dioceseIndex < this.props.screenProps.screenProps.diocese.length; dioceseIndex++) {

      var aDiocese = this.props.screenProps.screenProps.diocese[dioceseIndex];

      var aDioceseItem = { label: aDiocese.Name, value: aDiocese.Name, key: `${aDiocese.Name}-${dioceseIndex}` };

      dioceseArray.push(aDioceseItem);
    }

    var pmmChoicesArray = [];

    for (let pmmChoiceIndex = 0; pmmChoiceIndex < this.props.screenProps.screenProps.pmmChoices.length; pmmChoiceIndex++) {

      var aPmmChoice = this.props.screenProps.screenProps.pmmChoices[pmmChoiceIndex];

      var aPmmChoiceItem = { label: aPmmChoice.Name, value: aPmmChoice.Name }

      pmmChoicesArray.push(aPmmChoiceItem);
    }

    var itemsArray = [];

    for (let timeTableItemIndex = 0; timeTableItemIndex < this.state.timeTableItems.length; timeTableItemIndex++) {

      var aTimeTableItem = this.state.timeTableItems[timeTableItemIndex];

      if (aTimeTableItem['Show on Request to Speak form'] != null) {

        var itemNumber = 0;

        var timeTableItemTitle = aTimeTableItem.Title;

        if (aTimeTableItem.ItemNumber != null) {
          itemNumber = aTimeTableItem.ItemNumber;
        }

        var anItem = { label: timeTableItemTitle, value: aTimeTableItem.Title + " | " + itemNumber }

        itemsArray.push(anItem);
      }
    }

    itemsArray.push({ label: "Other items (not listed above)", value: "Other" })

    this.setState({ diocese: dioceseArray, pmmChoices: pmmChoicesArray, items: itemsArray });
  }

  nameTextDidChange(text: string) {
    this.setState({ nameText: text });
  }

  emailTextDidChange(text: string) {
    this.setState({ emailText: text });
  }

  queryTextDidChange(text: string) {
    this.setState({ queryText: text });
  }

  synodNumberTextDidChange(text: string) {
    this.setState({ synodNumberText: text });
  }

  otherItemNumberTextDidChange(text: string) {
    this.setState({ otherItemNumberText: text });
  }

  requestSummaryTextDidChange(text: string) {
    this.setState({ requestSummaryText: text });
  }

  requestReasonTextDidChange(text: string) {
    this.setState({ requestReasonText: text });
  }

  speakOnAmendmentOptionSelected(option: string) {
    this.setState({ speakOnAmendmentOption: option });
  }

  amendmentTextDidChange(text: string) {
    this.setState({ amendmentText: text });
  }

  addressingRemotelyOptionSelected(option: string) {
    this.setState({ addressingRemotelyOption: option });
  }

  forOrAgainstAmendmentOptionSelected(option: string) {
    this.setState({ forOrAgainstAmendmentOption: option });
  }

  maidenSpeechOptionSelected(option: string) {
    this.setState({ maidenSpeechOption: option });
  }

  forOrAgainstMotionOptionSelected(option: string) {
    this.setState({ forOrAgainstMotionOption: option });
  }

  requireMicrophoneOptionSelected(option: string) {
    this.setState({ requireMicrophoneOption: option });
  }

  firstNameTextDidChange(text: string) {
    this.setState({ firstNameText: text });
  }

  surnameTextDidChange(text: string) {
    this.setState({ surnameText: text });
  }

  postcodeTextDidChange(text: string) {
    this.setState({ postcodeText: text });
  }

  showSubjectPicker() {
    this.props.screenProps.screenProps.showSubjectPicker(true, this.props.route.params.form);
    Keyboard.dismiss();
  }

  showDiocesePicker() {

    this._diocesePicker.togglePicker(true);
  }

  showPmmChoicePicker() {
    this._pmmChoicePicker.togglePicker(true);
  }

  async sendEmail() {

    var that = this;

    Keyboard.dismiss();
    // that.clearForm();

    try {
      if (this.state.nameText !== '') {
        await AsyncStorage.setItem('FullName', this.state.nameText);
      }

      if (this.state.emailText !== '') {
        await AsyncStorage.setItem('Email', this.state.emailText);
      }

      if (this.state.selectedDiocese != null) {
        await AsyncStorage.setItem('Diocese', this.state.selectedDiocese);
      }

      if (this.state.synodNumberText !== '') {
        await AsyncStorage.setItem('SynodNumber', this.state.synodNumberText);
      }

    } catch (error) {
      console.log('error saving default form values: ', error);
    }

    var body = {};

    if (this.props.route.params.form === 'Request to speak') {
      var addressingRemotely = 'No';
      var speakOnAmendmentEmailText = "No";
      var forOrAgainstAmendment = "N/A"

      if (this.state.speakOnAmendmentOption === "Yes") {
        speakOnAmendmentEmailText = "Yes - " + this.state.amendmentText;
      }

      if (this.state.addressingRemotelyOption !== null) {
        addressingRemotely = this.state.addressingRemotelyOption;
      }

      if (this.state.forOrAgainstAmendmentOption != null) {
        forOrAgainstAmendment = this.state.forOrAgainstAmendmentOption;
      }

      var itemNumber = '';

      try {
        if (this.state.selectedItem === "Other") {
          itemNumber = "Other - " + this.state.otherItemNumberText;
        } else if (this.state.selectedItem != null && this.state.selectedItem.includes('|')) {

          var itemNumberString = this.state.selectedItem.split(' | ')[1];
          var itemTitleString = this.state.selectedItem.split(' | ')[0]

          if (itemNumberString === "0") {
            itemNumberString = "No item number";
          }

          itemNumber = itemNumberString + " - " + itemTitleString;
        } else {
          itemNumber = 'No item number: ' + this.state.selectedItem;
        }
      } catch (error) {
        console.log(error);
      }

      var subject = this.state.nameText + " | " + itemNumber;

      body = {
        "name": this.state.nameText,
        "email": this.state.emailText,
        "emailTo": this.props.screenProps.screenProps.selectedSubject.Email,
        "subject": subject,
        "contactForm": this.props.route.params.form,

        "diocese": this.state.selectedDiocese,
        "itemNumber": itemNumber,
        "synodNumber": this.state.synodNumberText,
        "amendmentText": speakOnAmendmentEmailText,
        "addressingRemotely": addressingRemotely,
        "forOrAgainstAmendment": forOrAgainstAmendment,
        "maidenSpeech": this.state.maidenSpeechOption,
        "forOrAgainst": this.state.forOrAgainstMotionOption,
        "requestSummary": this.state.requestSummaryText,
        "requestReason": this.state.requestReasonText,
        "requireMicrophone": this.state.requireMicrophoneOption,
      }

    } else if (this.props.route.params.form === "Private Members' Motions") {

      body = {
        "firstName": this.state.firstNameText,
        "surname": this.state.surnameText,
        "email": this.state.emailText,
        "emailTo": this.props.screenProps.screenProps.selectedSubject.Email,
        "contactForm": this.props.route.params.form,
        "pmmChoice": this.state.selectedPmmChoice,
        "synodNumber": this.state.synodNumberText,
        "postcode": this.state.postcodeText,
      }

    } else {

      body = {
        "name": this.state.nameText,
        "email": this.state.emailText,
        "emailTo": this.props.screenProps.screenProps.selectedSubject.Email,
        "subject": this.props.screenProps.screenProps.selectedSubject.Name,
        "contactForm": this.props.route.params.form,

        "query": this.state.queryText
      }
    }

    var alertTitle = "Your query has been submitted";
    var alertDescription = "A staff member of the Synod Office will be in touch shortly.";

    if (this.props.route.params.form === 'Request to speak') {
      var alertTitle = "Your Request to Speak has been submitted";
      var alertDescription = "Please note that this form is not sent directly to the Chair.";
    }

    if (this.props.route.params.form === "Private Members' Motions") {
      var alertTitle = "Your signature has been submitted";
      var alertDescription = "";
    }

    this.setState({ waitingForSendResponse: true })

    try {
      let response = await fetch('https://7rlqbbo2w0.execute-api.eu-west-1.amazonaws.com/default/generalSynodContact', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': 'CMUG3Q9aLp3Seqg5G2vGFa0saVib78A517Bokq3v'
        },
        body: JSON.stringify(body)
      });

      __DEV__ && console.log(response);

      this.setState({ waitingForSendResponse: false })

      if (response.status === 200) {
        FirebaseService.logEvent('contact_form_sent');

        var responseJson = await response.json();

        if (responseJson.body === 'Email sent') {

          Alert.alert(
            alertTitle,
            alertDescription,
            [
              { text: 'OK', onPress: () => that.clearForm() },
            ],
            { cancelable: false }
          );

        } else {

          Alert.alert(
            "Request couldn't be sent",
            "Sorry there was an issue sending your request. Please try again later",
            [
              { text: 'OK', onPress: () => console.log('OK Pressed') },
            ],
            { cancelable: false }
          );
        }
      } else {

        Alert.alert(
          "Request couldn't be sent",
          "Sorry there was an issue sending your request. Please try again later",
          [
            { text: 'OK', onPress: () => console.log('OK Pressed') },
          ],
          { cancelable: false }
        );
      }
    }
    catch (error) {
      this.setState({ waitingForSendResponse: false })
      console.error(error);
      return null;
    }
  }

  clearForm() {

    if (this.props.route.params.form === 'Request to speak') {

      this.setState({
        // nameText: '',
        // emailText: '',
        // selectedDiocese: null,
        selectedPmmChoice: null,
        selectedItem: null,
        otherItemNumberText: '',
        // synodNumberText: '',
        speakOnAmendmentOption: null,
        amendmentText: '',
        addressingRemotelyOption: null,
        forOrAgainstAmendmentOption: null,
        maidenSpeechOption: null,
        forOrAgainstMotionOption: null,
        requestSummaryText: '',
        requestReasonText: '',
        requireMicrophoneOption: null,
        firstNameText: '',
        surnameText: '',
        postcodeText: '',
      });

    } else if (this.props.route.params.form === "Private Members' Motions") {

      this.setState({
        selectedPmmChoice: null,
        firstNameText: '',
        surnameText: '',
        postcodeText: '',
      });

    } else {

      this.setState({
        // nameText: '',
        // emailText: '',
        queryText: '',

      });
    }
  }

  goToInfo() {
    this.props.navigation.navigate('Info');
  }

  render() {

    var sendButtonEnabled = true;

    if (this.props.route.params.form === 'Request to speak') {

      if (this.state.nameText === '' ||
        this.state.emailText === '' ||
        this.state.selectedDiocese == null ||
        this.state.selectedItem == null ||
        this.state.synodNumberText === '' ||
        this.state.maidenSpeechOption == null ||
        this.state.forOrAgainstMotionOption == null ||
        this.state.requestSummaryText === '' ||
        this.state.requestReasonText === '' ||
        this.state.requireMicrophoneOption == null) {
        sendButtonEnabled = false;
      }

      if (this.state.selectedItem === "Other" && this.state.otherItemNumberText === "") {
        sendButtonEnabled = false;
      }

    } else if (this.props.route.params.form === "Private Members' Motions") {

      if (this.state.firstNameText === '' ||
        this.state.surnameText === '' ||
        this.state.emailText === '' ||
        this.state.selectedPmmChoice == null ||
        this.state.synodNumberText === '') {
        sendButtonEnabled = false;
      }

    } else {

      if (this.state.nameText === '' || this.state.emailText === '' || this.state.queryText === '' || this.props.screenProps.screenProps.selectedSubject.Name === '') {
        sendButtonEnabled = false;
      }
    }

    if (!validator.isEmail(this.state.emailText)) {
      sendButtonEnabled = false;
    }

    var subjectText = this.props.screenProps.screenProps.selectedSubject.Name;
    var subjectTextColor = '#374047'
    if (subjectText === '') {
      subjectText = 'Please select a subject'
      subjectTextColor = '#AAA'
    }

    var pickerArrowLayout = null;
    var pickerPadding = Platform.OS === 'web' ? 0 : 14;

    var pickerArrowLayout = (
      <View style={{ backgroundColor: '#A08BC1', height: 50, width: 50, justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: -1, right: 0, overflow: 'hidden' }} pointerEvents='none'>
        <Image resizeMode={Platform.OS === 'web' ? 'center' : null} style={styles.icon} source={images.Button_Disclosure_White_Down} />
      </View>
    )

    var sendLoadingIndicatorLayout = null;

    if (this.state.waitingForSendResponse) {
      sendLoadingIndicatorLayout = (
        <ActivityIndicator color={'white'} />
      );
    }

    if (this.props.route.params.form === 'Request to speak') {

      var amendmentLayout = null;

      if (this.state.speakOnAmendmentOption === 'Yes') {
        amendmentLayout = (
          <View>
            <Text style={styles.formHeader}>Which amendment do you wish to speak on?</Text>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ flex: 1, fontSize: 18, height: 42, marginHorizontal: 10, marginVertical: 4, padding: 0, height: 124 }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.amendmentTextDidChange(text)}
                multiline={true}
                value={this.state.amendmentText}
                placeholder={'Enter the amendment you wish to speak on'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
                textAlignVertical='top'
              />
            </View>

            <Text style={styles.formHeader}>Are you speaking for or against the amendment?</Text>
            <CheckBoxGroup options={['For', 'Against']} selectedOption={this.state.forOrAgainstAmendmentOption} optionSelected={this.forOrAgainstAmendmentOptionSelected.bind(this)} />
          </View>
        )
      }

      var otherItemNumberLayout = null;

      if (this.state.selectedItem === "Other") {
        otherItemNumberLayout = (
          <View>
            <Text style={styles.formHeader}>Other item number *</Text>

            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ flex: 1, fontSize: 18, height: 42, marginHorizontal: 10, marginVertical: 4, padding: 0, }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.otherItemNumberTextDidChange(text)}
                value={this.state.otherItemNumberText}
                placeholder={'Enter item number'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
              />
            </View>
          </View>
        )
      }

      var contentLayout = (
        <ScrollView keyboardShouldPersistTaps="always">

          <View style={{ margin: 10 }}>

            <Text style={styles.text}>This form applies to debates on a motion or an amendment (including Legislative Business and Liturgical Business). Fields marked with a * are required.</Text>

            <Text style={styles.formHeader}>Name *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.nameTextDidChange(text)}
                value={this.state.nameText}
                placeholder={'Enter your full name'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoCompleteType='name'
                textContentType='name'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Synod Number *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ flex: 1, textAlignVertical: 'center', fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: 52, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.synodNumberTextDidChange(text)}
                multiline={Platform.OS === 'web' ? false : true}
                value={this.state.synodNumberText}
                placeholder={'Please enter your Synod number from your lanyard'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
                keyboardType={'numeric'}
              />
            </View>

            <Text style={styles.formHeader}>Email *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.emailTextDidChange(text)}
                value={this.state.emailText}
                placeholder={'Provide an email address'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                textContentType='emailAddress'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Diocese or Constituency *</Text>

            <View style={{ flex: -1, height: Platform.OS === 'web' ? 50 : undefined, flexDirection: 'row', alignItems: 'stretch', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#A08BC1', borderRadius: 5, marginBottom: 4, marginTop: 4, overflow: 'hidden' }}>
              <View style={{ flex: -1, width: '100%', height: Platform.OS === 'web' ? 50 : undefined, marginLeft: Platform.OS === 'web' ? 0 : 0, marginVertical: Platform.OS === 'web' ? 0 : 0, padding: 0, justifyContent: 'center' }}>
                <RNPickerSelect
                  ref={(c) => this._diocesePicker = c}
                  useNativeAndroidPickerStyle={false}
                  placeholder={{
                    label: 'Select your Diocese or Constituency',
                    value: null,
                  }}
                  items={this.state.diocese}
                  onValueChange={value => {
                    this.setState({
                      selectedDiocese: value,
                    });
                  }}
                  style={{ inputAndroidContainer: { paddingVertical: 8 }, viewContainer: { paddingLeft: 10, paddingVertical: pickerPadding, }, placeholder: { fontFamily: 'Inter-Regular', fontSize: 17, letterSpacing: -0.2, color: '#AAA' }, inputIOS: { fontFamily: 'Inter-Regular', fontSize: 17, letterSpacing: -0.2, color: '#374047' }, inputAndroid: { fontSize: 18, color: '#374047' }, inputWeb: { borderWidth: 0, margin: 0, padding: 0, paddingHorizontal: 10, fontSize: 18, color: this.state.selectedDiocese ? '#374047' : '#AAA', height: 50, } }}
                  value={this.state.selectedDiocese}
                />
              </View>
              {pickerArrowLayout}
            </View>

            <Text style={styles.formHeader}>Item you wish to speak on *</Text>

            <View style={{ flex: -1, height: Platform.OS === 'web' ? 50 : undefined, flexDirection: 'row', alignItems: 'stretch', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#A08BC1', borderRadius: 5, marginBottom: 4, marginTop: 4, overflow: 'hidden' }}>
              <View style={{ flex: -1, width: '100%', height: Platform.OS === 'web' ? 50 : undefined, marginLeft: Platform.OS === 'web' ? 0 : 0, paddingVertical: Platform.OS === 'web' ? 0 : 0, padding: 0, justifyContent: 'center' }}>
                <RNPickerSelect
                  ref={(c) => this._itemNumberPicker = c}
                  useNativeAndroidPickerStyle={false}
                  placeholder={{
                    label: 'Select the item you wish to speak on',
                    value: null,
                  }}
                  items={this.state.items}
                  onValueChange={value => {
                    this.setState({
                      selectedItem: value,
                    });
                  }}
                  style={{ inputAndroidContainer: { paddingVertical: 8 }, viewContainer: { paddingLeft: 10, paddingVertical: pickerPadding, }, placeholder: { fontFamily: 'Inter-Regular', fontSize: 17, letterSpacing: -0.2, color: '#AAA' }, inputIOS: { fontFamily: 'Inter-Regular', fontSize: 17, letterSpacing: -0.2, color: '#374047' }, inputAndroid: { fontSize: 18, color: '#374047' }, inputWeb: { borderWidth: 0, margin: 0, padding: 0, paddingHorizontal: 10, fontSize: 18, color: this.state.selectedItem ? '#374047' : '#AAA', height: 50, } }}
                  value={this.state.selectedItem}
                />
              </View>
              {pickerArrowLayout}
            </View>

            {otherItemNumberLayout}

            <Text style={styles.formHeader}>Will you be addressing the Synod remotely (e.g. via Zoom)?</Text>
            <CheckBoxGroup options={['Yes', 'No']} selectedOption={this.state.addressingRemotelyOption} optionSelected={this.addressingRemotelyOptionSelected.bind(this)} />

            <Text style={styles.formHeader}>Are you for or against the main motion? *</Text>
            <CheckBoxGroup options={['For', 'Against']} selectedOption={this.state.forOrAgainstMotionOption} optionSelected={this.forOrAgainstMotionOptionSelected.bind(this)} />

            <Text style={styles.formHeader}>Do you wish to speak to an amendment on this item?</Text>
            <CheckBoxGroup options={['Yes', 'No']} selectedOption={this.state.speakOnAmendmentOption} optionSelected={this.speakOnAmendmentOptionSelected.bind(this)} />

            {amendmentLayout}

            <Text style={styles.formHeader}>Request Summary *</Text>

            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: 120, paddingHorizontal: 10, paddingVertical: 10, padding: 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.requestSummaryTextDidChange(text)}
                multiline={true}
                value={this.state.requestSummaryText}
                placeholder={'Enter a brief summary of your request if you were called upon to speak'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
                textAlignVertical='top'
              />
            </View>

            <Text style={styles.formHeader}>Request Reason *</Text>

            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: 120, paddingHorizontal: 10, paddingVertical: 10, padding: 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.requestReasonTextDidChange(text)}
                multiline={true}
                value={this.state.requestReasonText}
                placeholder={'Briefly note why you wish to speak (e.g. relevant committee membership, professional and/or personal experience, theological study etc.)'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
                textAlignVertical='top'
              />
            </View>

            <Text style={styles.formHeader}>Is this a maiden speech? *</Text>
            <CheckBoxGroup options={['Yes', 'No']} selectedOption={this.state.maidenSpeechOption} optionSelected={this.maidenSpeechOptionSelected.bind(this)} />

            <Text style={{ marginTop: 10, fontFamily: 'Inter-Bold', fontSize: 21, fontWeight: Platform.OS === 'android' ? 'normal' : 'bold', color: '#333' }}>Accessibility</Text>

            <Text style={[styles.smallPrint, styles.smallPrintMargin]}>Do you require a roving microphone to be brought to your seat? *</Text>

            <CheckBoxGroup options={['Yes', 'No']} selectedOption={this.state.requireMicrophoneOption} optionSelected={this.requireMicrophoneOptionSelected.bind(this)} />

            <Text style={styles.smallPrint}>If you are unable to stand when you wish to be called, please raise your hand.</Text>

            <Text style={[styles.smallPrint, styles.smallPrintMargin]}>The information in this form is used to help the Chair of a debate to structure the debate in the most effective way. The more concise you can be in this form, the more helpful it will be to the Chair of the debate. Submitting the form does not guarantee you the right to speak; nor does it mean that only those who have filled in the form would be called to speak.</Text>

            <Text style={[styles.smallPrintBold, styles.smallPrintMargin]}>This form is not sent directly to the Chair, but is administrated by the Central Secretariat team.</Text>

            <Text style={[styles.smallPrint, styles.smallPrintMargin]}>Please make sure you have filled the form out correctly. You will need to fill out the fields marked with a * before you can submit your form.</Text>

            <View style={{ alignItems: 'center' }}>
              <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'center' }} onPress={this.sendEmail.bind(this)} disabled={!sendButtonEnabled}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 10, width: Platform.OS === 'web' ? 334 : null, maxWidth: 334, height: 50, backgroundColor: (sendButtonEnabled) ? '#A08BC1' : '#DAD5EF', justifyContent: 'center', borderRadius: 5, shadowColor: '#000', shadowOffset: { width: 0, height: 1 }, shadowRadius: 1, shadowOpacity: 0.2 }}>
                  <Text style={{ textAlign: 'center', color: (sendButtonEnabled) ? '#FFF' : '#FFF', fontSize: 18, fontWeight: 'bold', marginRight: 10 }}>Send</Text>
                  {sendLoadingIndicatorLayout}
                </View>
              </TouchableOpacity>
            </View>

          </View>

        </ScrollView >
      )

    } else if (this.props.route.params.form === "Private Members' Motions") {

      var contentLayout = (
        <ScrollView keyboardShouldPersistTaps="always">

          <View style={{ margin: 10 }}>

            <Text style={styles.text}>By filling in and submitting this form, Synod members are including his or her name on the list of members who support a debate on selected PMM. More details on the PMMs can be found on <Text style={{ color: '#007bff' }} onPress={() => { Linking.openURL('https://www.churchofengland.org/more/policy-and-thinking/work-general-synod/private-members-motions') }}>the Church of England website.</Text></Text>

            <Text style={styles.formHeader}>Name *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.firstNameTextDidChange(text)}
                value={this.state.firstNameText}
                placeholder={'Enter your name'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoCompleteType='name'
                textContentType='name'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Surname *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.surnameTextDidChange(text)}
                value={this.state.surnameText}
                placeholder={'Enter your surname'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoCompleteType='name'
                textContentType='name'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Synod Number *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ flex: 1, textAlignVertical: 'center', fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: 52, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.synodNumberTextDidChange(text)}
                multiline={Platform.OS === 'web' ? false : true}
                value={this.state.synodNumberText}
                placeholder={'Please enter your Synod number from your lanyard'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
                keyboardType={'numeric'}
              />
            </View>

            <Text style={styles.formHeader}>Email *</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.emailTextDidChange(text)}
                value={this.state.emailText}
                placeholder={'Provide an email address'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                textContentType='emailAddress'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Postcode</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.postcodeTextDidChange(text)}
                value={this.state.postcodeText}
                placeholder={'Enter your postcode'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoCompleteType='postal-code'
                textContentType='postalCode'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>PMM to be signed *</Text>

            <View style={{ flex: -1, height: Platform.OS === 'web' ? 50 : undefined, flexDirection: 'row', alignItems: 'stretch', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#A08BC1', borderRadius: 5, marginBottom: 4, marginTop: 4, overflow: 'hidden' }}>
              <View style={{ flex: -1, width: '100%', height: Platform.OS === 'web' ? 50 : undefined, marginLeft: Platform.OS === 'web' ? 0 : 0, marginVertical: Platform.OS === 'web' ? 0 : 0, padding: 0, justifyContent: 'center' }}>
                <RNPickerSelect
                  ref={(c) => this._pmmChoicePicker = c}
                  useNativeAndroidPickerStyle={false}
                  placeholder={{
                    label: 'Select a PMM',
                    value: null,
                  }}
                  items={this.state.pmmChoices}
                  onValueChange={value => {
                    this.setState({
                      selectedPmmChoice: value,
                    });
                  }}
                  style={{ inputAndroidContainer: { paddingVertical: 8 }, viewContainer: { paddingLeft: 10, paddingVertical: pickerPadding }, placeholder: { fontFamily: 'Inter-Regular', fontSize: 17, letterSpacing: -0.2, color: '#AAA' }, inputIOS: { fontFamily: 'Inter-Regular', fontSize: 17, letterSpacing: -0.2, color: '#374047' }, inputAndroid: { fontSize: 18, color: '#374047' }, inputWeb: { borderWidth: 0, margin: 0, padding: 0, paddingHorizontal: 10, fontSize: 18, color: this.state.selectedPmmChoice ? '#374047' : '#AAA', height: 50, } }}
                  value={this.state.selectedPmmChoice}
                />
              </View>
              {pickerArrowLayout}
            </View>

            <View style={{ alignItems: 'center' }}>
              <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'center' }} onPress={this.sendEmail.bind(this)} disabled={!sendButtonEnabled}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 10, width: Platform.OS === 'web' ? 334 : null, maxWidth: 334, height: 50, backgroundColor: (sendButtonEnabled) ? '#A08BC1' : '#DAD5EF', justifyContent: 'center', borderRadius: 5, shadowColor: '#000', shadowOffset: { width: 0, height: 1 }, shadowRadius: 1, shadowOpacity: 0.2 }}>
                  <Text style={{ textAlign: 'center', color: (sendButtonEnabled) ? '#FFF' : '#FFF', fontSize: 18, fontWeight: 'bold', marginRight: 10 }}>Send</Text>
                  {sendLoadingIndicatorLayout}
                </View>
              </TouchableOpacity>
            </View>

          </View>
        </ScrollView>
      );


    } else {

      var contentLayout = (
        <ScrollView keyboardShouldPersistTaps="always">

          <View style={{ margin: 10 }}>

            <Text style={styles.text}>
              {'If you have any questions, please use the form below to contact the Synod Office.'}
            </Text>

            <Text style={styles.formHeader}>Name</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.nameTextDidChange(text)}
                value={this.state.nameText}
                placeholder={'Enter your full name'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoCompleteType='name'
                textContentType='name'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Email</Text>

            <View style={{ flex: 1, maxHeight: Platform.OS === 'web' ? 52 : null, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: Platform.OS === 'web' ? 52 : 42, marginHorizontal: Platform.OS === 'web' ? 0 : 10, marginVertical: Platform.OS === 'web' ? 0 : 4, padding: 0, paddingHorizontal: Platform.OS === 'web' ? 10 : 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.emailTextDidChange(text)}
                value={this.state.emailText}
                placeholder={'Provide an email address'}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                keyboardType='email-address'
                autoCorrect={false}
                textContentType='emailAddress'
                returnKeyType={'default'}
              />
            </View>

            <Text style={styles.formHeader}>Subject</Text>

            <TouchableOpacity onPress={this.showSubjectPicker.bind(this)}>
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'stretch', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#A08BC1', borderRadius: 5, marginBottom: 4, marginTop: 4, height: 52 }}>
                <View style={{ flex: 1, marginHorizontal: 10, marginVertical: 4, padding: 0, justifyContent: 'center' }}>
                  <Text style={{ fontSize: 18, color: subjectTextColor }}>
                    {subjectText}
                  </Text>
                </View>
                <View style={{ backgroundColor: '#A08BC1', height: 50, width: 50, justifyContent: 'center', alignItems: 'center' }}>
                  <Image resizeMode={Platform.OS === 'web' ? 'center' : null} style={styles.icon} source={images.Button_Disclosure_White_Down} />
                </View>
              </View>
            </TouchableOpacity>

            <Text style={styles.formHeader}>Query</Text>

            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: '#FFF', borderWidth: 1, borderColor: '#CCC', borderRadius: 5, marginBottom: 4, marginTop: 4 }}>
              <TextInput
                style={{ fontFamily: 'Inter-Regular', flex: 1, fontSize: 17, height: 120, paddingHorizontal: 10, paddingVertical: 10, padding: 0, letterSpacing: -0.2, color: '#374047' }}
                inputAccessoryViewID={'doneToolbarID'}
                underlineColorAndroid="transparent"
                onChangeText={(text) => this.queryTextDidChange(text)}
                value={this.state.queryText}
                placeholder={'Enter your query here'}
                multiline={true}
                placeholderTextColor={'#AAA'}
                autoCapitalize={'none'}
                autoCorrect={false}
                returnKeyType={'default'}
                textAlignVertical='top'
              />
            </View>

            <View style={{ alignItems: 'center' }}>
              <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'center' }} onPress={this.sendEmail.bind(this)} disabled={!sendButtonEnabled}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 10, width: Platform.OS === 'web' ? 334 : null, maxWidth: 334, height: 50, backgroundColor: (sendButtonEnabled) ? '#A08BC1' : '#DAD5EF', justifyContent: 'center', borderRadius: 5, shadowColor: '#000', shadowOffset: { width: 0, height: 1 }, shadowRadius: 1, shadowOpacity: 0.2 }}>
                  <Text style={{ textAlign: 'center', color: (sendButtonEnabled) ? '#FFF' : '#FFF', fontSize: 18, fontWeight: 'bold', marginRight: 10 }}>Send</Text>
                  {sendLoadingIndicatorLayout}
                </View>
              </TouchableOpacity>
            </View>

          </View>

        </ScrollView>
      )
    }

    var contentContainerLayout = (
      <View style={{ flex: 1 }}>
        {contentLayout}
      </View>
    )

    if (Platform.OS === 'ios') {
      contentContainerLayout = (
        <KeyboardAvoidingView style={{ flex: 1, paddingLeft: this.props.screenProps.screenProps.safeAreaInsets.left, paddingRight: this.props.screenProps.screenProps.safeAreaInsets.right }} keyboardVerticalOffset={64} behavior="padding">
          {contentLayout}
          <InputAccessoryView nativeID={'doneToolbarID'}>
            <View style={{ alignItems: 'flex-end', backgroundColor: '#F3F2F7', borderTopWidth: 1, borderColor: '#AAA' }}>
              <TouchableOpacity onPress={() => Keyboard.dismiss()}>
                <Text style={{ padding: 13, color: '#382E73' }}>Done</Text>
              </TouchableOpacity>
            </View>
          </InputAccessoryView>
        </KeyboardAvoidingView>
      )
    }

    return (
      <View style={{ flex: 1, backgroundColor: '#F3F2F7' }}>
        {contentContainerLayout}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  text: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#374047',
  },
  textMargin: {
    marginTop: 10,
  },
  formHeader: {
    fontFamily: 'Inter-Regular',
    fontSize: 16,
    lineHeight: 23,
    letterSpacing: -0.2,
    color: '#777',
    marginTop: 16,
  },
  smallPrint: {
    fontFamily: 'Inter-Regular',
    fontSize: 16,
    lineHeight: 23,
    letterSpacing: -0.2,
    color: '#777',
  },
  smallPrintBold: {
    fontFamily: 'Inter-Bold',
    fontSize: 16,
    fontWeight: Platform.OS === 'android' ? 'normal' : 'bold',
    lineHeight: 23,
    letterSpacing: -0.2,
    color: '#999',
    marginTop: 16,
  },
  smallPrintMargin: {
    marginTop: 16,
  },
  icon: Platform.select({
    web: {
      height: Platform.OS === 'web' ? 44 : undefined,
      width: Platform.OS === 'web' ? 44 : undefined,
      alignSelf: 'center',
    },
    default: {
      alignSelf: 'center',
    }
  }),
});