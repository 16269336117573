import React from 'react';
import 'react-native-gesture-handler';
import App from './App';
import { registerRootComponent } from 'expo';
import { name as appName } from './app.json';
import { AppRegistry, Platform } from 'react-native';
import * as firebase from 'firebase/app';
import messaging from '@react-native-firebase/messaging';
import FirebaseService from './src/controllers/FirebaseService';

async function setupFirebase() {
  if (Platform.OS === 'web') {
    const firebaseConfig = {
      apiKey: "AIzaSyBT9xtsMnVtjB6AgTOuXYC4Rko2I4mb-uU",
      authDomain: "synod-43aa3.firebaseapp.com",
      databaseURL: "https://synod-43aa3.firebaseio.com",
      projectId: "synod-43aa3",
      storageBucket: "synod-43aa3.appspot.com",
      messagingSenderId: "683597182930",
      appId: "1:683597182930:web:b4d7594c3b43d356563a0b"
    };

    const app = firebase.initializeApp(firebaseConfig);
  } else {
    const app = await FirebaseService.initializeApp();

    __DEV__ && console.log(app);

    if (app) {
      messaging().setBackgroundMessageHandler(async remoteMessage => {
        console.log('Message handled in the background', remoteMessage);
      });
    }
  }
}

function HeadlessCheck({ isHeadless }) {
  if (isHeadless) {
    return null;
  }

  return <App />
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
setupFirebase();
registerRootComponent(HeadlessCheck);