/**
 * Synod
 * https://github.com/facebook/react-native
 * @flow
 */
import React, { Component } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Platform,
  StyleSheet,
  Dimensions,
  Button,
  ScrollView,
  Alert,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
} from 'react-native';
import images from '../resources/images';

type Props = {

}

type State = {

}

export default class ContactMenuScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    // set header button functions (crashes if not on a delay)

    var that = this;
    setTimeout(() => {
      that.props.navigation.setParams({ goToInfo: that.goToInfo.bind(that) });
    }, 100);

    this.updateHeader();
  }

  updateHeader() {
    this.props.navigation.setOptions({
      title: 'Contact',
      headerStyle: {
        backgroundColor: '#382E73',
        borderBottomWidth: 0,
        height: Platform.OS === 'web' ? 44 : undefined,
      },
      headerTintColor: '#FFFFFF',
      headerTitleAlign: 'center',
      headerTitleStyle: {
        fontFamily: 'Inter-SemiBold',
        fontSize: 17,
        fontWeight: Platform.OS === 'android' ? 'normal' : '600',
        letterSpacing: -0.2,
        color: '#F1F3F5',
        marginHorizontal: 16,
        paddingHorizontal: 0,
      },
      headerRight: () => (
        <TouchableOpacity style={{ height: 44, width: 44, alignContent: 'center', justifyContent: 'center', marginHorizontal: 8, }} onPress={() => this.props.route.params && this.props.route.params.goToInfo()}>
          <Image resizeMode={Platform.OS === 'web' ? 'center' : null} source={images.Button_Info} style={styles.icon} />
        </TouchableOpacity>
      ),
    });
  }

  goToInfo() {
    this.props.navigation.navigate('Info');
  }

  goToContactUsForm() {

    this.props.screenProps.screenProps.showSubjectPicker(false, 'General Query');

    this.props.navigation.navigate('ContactPage', {
      form: 'General Query'
    });
  }

  goToRequestToSpeakForm() {

    this.props.screenProps.screenProps.showSubjectPicker(false, 'Request to speak');

    this.props.navigation.navigate('ContactPage', {
      form: 'Request to speak'
    });
  }

  goToPMMForm() {

    this.props.screenProps.screenProps.showSubjectPicker(false, "Private Members' Motions");

    this.props.navigation.navigate('ContactPage', {
      form: "Private Members' Motions"
    });
  }

  render() {

    return (
      <View style={{ flex: 1, backgroundColor: '#F3F2F7' }}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingLeft: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.left, paddingRight: this.props.screenProps.screenProps.safeAreaInsets && this.props.screenProps.screenProps.safeAreaInsets.right, paddingBottom: 16, }}>
          <View style={{ margin: 16 }}>
            <Text style={styles.text}>
              {'If you have any questions, please use the ‘General Query’ button below and the Synod Office will be in touch shortly.'}
            </Text>
            <Text style={[styles.text, styles.textMargin]}>
              {'To request to speak, please use the ‘Request to Speak’ button below.'}
            </Text>
            <Text style={[styles.text, styles.textMargin]}>
              {'To sign a PMM, please use the ‘Private Members’ Motions’ button below.'}
            </Text>
          </View>
          <View style={{ alignItems: 'center', alignItems: 'stretch' }}>
            <TouchableOpacity style={styles.button} onPress={this.goToContactUsForm.bind(this)}>
              <Text style={styles.buttonText} allowFontScaling={false}>{"General Query Support"}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={this.goToRequestToSpeakForm.bind(this)}>
              <Text style={styles.buttonText} allowFontScaling={false}>{"Request to Speak"}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={this.goToPMMForm.bind(this)}>
              <Text style={styles.buttonText} allowFontScaling={false}>{"Private Members' Motions"}</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  text: {
    fontFamily: 'Inter-Regular',
    fontSize: 17,
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#374047',
  },
  textMargin: {
    marginTop: 20,
  },
  button: {
    width: '100%',
    maxWidth: 354,
    height: 50,
    backgroundColor: '#A08BC1',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    alignSelf: 'center',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 8,
  },
  buttonText: {
    fontFamily: 'Inter-Bold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#F1F3F5'
  },
  icon: Platform.select({
    web: {
      height: Platform.OS === 'web' ? 44 : undefined,
      width: Platform.OS === 'web' ? 44 : undefined,
      alignSelf: 'center',
    },
    default: {
      alignSelf: 'center',
    }
  }),
});