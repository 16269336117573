import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { Dimensions, Modal, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { isTablet } from 'react-native-device-info';
import { isIphoneX } from 'react-native-iphone-x-helper';
import RNModal from 'react-native-modal';

const AccountModal = ({ screenProps, visible, close, user, userInfo, logout }) => {
  const [activeUser, setActiveUser] = useState(user);
  const [activeUserInfo, setActiveUserInfo] = useState(userInfo);

  useEffect(() => {
    setActiveUser(user);
    setActiveUserInfo(userInfo);
  }, [user, userInfo]);

  // const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {

    __DEV__ && console.log("Visible: ", visible);
    __DEV__ && console.log('user', user);
    __DEV__ && console.log('userInfo', userInfo);
    // setIsVisible(visible);

  }, [visible]);

  const modalStyles = StyleSheet.create({
    modalDimensions: {
      height: Dimensions.get('window').height,
      width: isTablet() ? null : Dimensions.get('window').width,
    },
    modalWeb: {
      alignItems: undefined,
      justifyContent: undefined,
      width: Platform.OS === 'web' ? '100%' : Dimensions.get('window').width,
      height: Platform.OS === 'web' ? null : Dimensions.get('window').height,
      overflow: 'hidden',
      borderRadius: Platform.OS === 'web' ? 0 : 10,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      alignSelf: 'center',
      top: Platform.OS === 'web' ? 0 : 25,
      margin: 0,
    },
  });

  if (Platform.OS === 'web') {
    return (
      <View>
        <RNModal
          isVisible={visible}
          onBackdropPress={() => close()}
          onBackButtonPress={() => close()}
          backdropTransitionInTiming={200}
          backdropTransitionOutTiming={0}
          style={modalStyles.modalWeb}>
          <View style={{ height: '100%' }}>
            <View style={[styles.modal, modalStyles.modalDimensions]}>
              <View style={styles.modalHeaderContainer}>
                <TouchableOpacity
                  style={styles.modalCloseButton}
                  hitSlop={styles.modalCloseButtonHitSlop}
                  onPress={close}>
                  <Text style={styles.modalCloseButtonText}>{'Close'}</Text>
                </TouchableOpacity>
              </View>
              <ScrollView
                style={styles.modalScrollView}
                contentContainerStyle={[
                  styles.modalScrollViewContainer,
                  {
                    paddingStart: screenProps.safeAreaInsets.left,
                    paddingEnd: screenProps.safeAreaInsets.right,
                  }]}>
                <View style={styles.modalView}>
                  <Text style={styles.signInText}>
                    {'Signed in as'}
                  </Text>
                  <Text style={styles.userText}>
                    {userInfo && userInfo.email}
                  </Text>
                </View>
                <TouchableOpacity style={styles.button} onPress={() => logout()}>
                  <Text style={styles.buttonText} allowFontScaling={false}>
                    {'Log out'}
                  </Text>
                </TouchableOpacity>
              </ScrollView>
            </View>
          </View>
        </RNModal>
      </View>
    )
  }

  return (
    <View>
      <StatusBar backgroundColor="transparent" translucent />
      <Modal
        visible={visible}
        // onDismiss={() => Platform.OS === 'android' && close()}
        onRequestClose={() => Platform.OS === 'android' && close()}
        presentationStyle="pageSheet"
        animationType='slide'>
        <View>
          <View style={[styles.modal, modalStyles.modalDimensions]}>
            <View style={styles.modalHeaderContainer}>
              <TouchableOpacity
                style={styles.modalCloseButton}
                hitSlop={styles.modalCloseButtonHitSlop}
                onPress={() => close()}>
                <Text style={styles.modalCloseButtonText}>{'Close'}</Text>
              </TouchableOpacity>
            </View>
            <ScrollView
              style={styles.modalScrollView}
              contentContainerStyle={[
                styles.modalScrollViewContainer,
                {
                  paddingStart: screenProps.safeAreaInsets.left,
                  paddingEnd: screenProps.safeAreaInsets.right,
                }]}>
              <View style={styles.modalView}>
                <Text style={styles.signInText}>
                  {'Signed in as'}
                </Text>
                <Text style={styles.userText}>
                  {userInfo && userInfo.email}
                </Text>
              </View>
              <TouchableOpacity style={styles.button} onPress={() => logout()}>
                <Text style={styles.buttonText} allowFontScaling={false}>
                  {'Log out'}
                </Text>
              </TouchableOpacity>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  )
};

const styles = StyleSheet.create({
  modal: {
    // marginTop: isTablet() ? 30 : 0,
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  modalHeaderContainer: {
    height: Platform.OS === 'web' ? 44 : 48,
    backgroundColor: '#382E73',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopStartRadius: Platform.OS === 'web' || Platform.OS === 'android' ? 0 : 10,
    borderTopEndRadius: Platform.OS === 'web' || Platform.OS === 'android' ? 0 : 10,
  },
  modalHeaderContainerWeb: {
    height: 48,
    backgroundColor: '#382E73',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopStartRadius: Platform.OS === 'web' ? 0 : 10,
    borderTopEndRadius: Platform.OS === 'web' ? 0 : 10,
  },
  modalCloseButton: {
    position: 'absolute',
    left: Platform.OS === 'web' ? null : 16,
    right: Platform.OS === 'web' ? 16 : null,
  },
  modalCloseButtonWeb: {
    position: 'absolute',
    left: Platform.OS === 'web' ? null : 16,
    right: Platform.OS === 'web' ? 16 : null,
  },
  modalCloseButtonHitSlop: {
    top: 12,
    bottom: 12,
    left: 10,
    right: 10,
  },
  modalCloseButtonText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#F1F3F5',
  },
  modalScrollView: {
    backgroundColor: '#F3F2F7',
    height: Platform.OS === 'web' ? '100%' : null,
  },
  modalScrollViewContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    paddingTop: 16,
    paddingBottom: isIphoneX() ? 100 : 44,
    marginHorizontal: 10,
    width: Platform.OS === 'web' ? '66%' : '100%',
    height: Platform.OS === 'web' ? '100%' : null,
    alignSelf: 'center',
    backgroundColor: '#F3F2F7',
  },
  modalView: {
    paddingHorizontal: Platform.OS === 'web' ? 0 : isTablet() ? 20 : 10,
  },
  modalScrollViewContainerWeb: {
    flexGrow: 1,
    flexDirection: 'column',
    marginHorizontal: 20,
    paddingBottom: Platform.OS === 'ios' ? 60 : 16,
    width: '66%',
    paddingTop: 16,
    alignSelf: 'center',
  },
  signInText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 15,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: -0.2,
    color: '#7F8A93',
  },
  userText: {
    fontFamily: 'Inter-SemiBold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    color: '#212529',
    marginTop: 4,
  },
  button: {
    width: '100%',
    maxWidth: 354,
    height: 44,
    backgroundColor: '#A08BC1',
    borderRadius: 4,
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    alignSelf: 'center',
    marginTop: 24,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 8,
  },
  buttonText: {
    fontFamily: 'Inter-Bold',
    fontSize: 17,
    fontWeight: Platform.OS === 'android' ? 'normal' : 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: -0.2,
    textAlign: 'center',
    color: '#F1F3F5'
  },
});

export default AccountModal;